export default {
  sm: '0px 8px 24px rgba(209, 83, 51, 0.2)',
  md:
    '0px 24px 32px rgba(34, 51, 72, 0.12), 0px -4px 32px rgba(34, 51, 72, 0.04)',
  lg: '0px 6px 18px rgba(34, 51, 72, 0.1)',
  xl: '',
  '2xl': '',
  outline: '',
  inner: '',
  none: 'none',
};
