import React from 'react';

const facebook = {
  path: (
    <svg fill="none">
      <path
        d="M14.0005 1.40039C7.04171 1.40039 1.40039 7.06529 1.40039 14.0531C1.40039 20.3204 5.94297 25.5108 11.899 26.5159V16.6929H8.85952V13.1579H11.899V10.5514C11.899 7.52715 13.7385 5.87909 16.4255 5.87909C17.7124 5.87909 18.8184 5.97539 19.1395 6.0178V9.17899L17.2758 9.1799C15.8148 9.1799 15.5331 9.87691 15.5331 10.9001V13.1561H19.0192L18.5645 16.691H15.5331V26.6004C21.7673 25.8385 26.6004 20.5159 26.6004 14.0495C26.6004 7.06529 20.9591 1.40039 14.0005 1.40039Z"
        fill="currentColor"
      />
    </svg>
  ),
  viewBox: '0 0 28 28',
};

export default facebook;
