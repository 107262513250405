import camera from './Camera';
import carat from './Carat';
import close from './Close';
import download from './Download';
import ellipse from './Ellipse';
import email from './Email';
import eye from './Eye';
import facebook from './Facebook';
import filter from './Filter';
import folder from './Folder';
import instagram from './Instagram';
import linkedIn from './LinkedIn';
import menu from './Menu';
import pencil from './Pencil';
import person from './Person';
import phone from './Phone';

export default {
  camera,
  carat,
  close,
  download,
  ellipse,
  email,
  eye,
  facebook,
  filter,
  folder,
  instagram,
  linkedIn,
  pencil,
  person,
  phone,
  menu,
};
