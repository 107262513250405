import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  GraphQLDateTime: any;
  JSONObject: any;
};

export type AddAdminInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type AddMemberExpertiseInput = {
  userId: Scalars['ID'];
  expertiseId: Scalars['ID'];
};

export type AddMemberInitiativeInput = {
  userId: Scalars['ID'];
  initiativeId: Scalars['ID'];
  hasPast: Scalars['Boolean'];
};

export type AddRequestGuestPeerInput = {
  requestId: Scalars['ID'];
  guest: GuestInput;
};

export type AddRequestPeerInput = {
  requestId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type Address = {
  streetAddress?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  streetAddress?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type AdvisorCvInput = {
  userId: Scalars['String'];
  cv: Scalars['String'];
};

export type AdvisorRole = {
  id?: Maybe<Scalars['ID']>;
  advisorRole?: Maybe<Scalars['String']>;
};

export type Analytics = {
  dateRange?: Maybe<DateRange>;
  requests?: Maybe<Scalars['Int']>;
  matches?: Maybe<Scalars['Int']>;
  members?: Maybe<Scalars['Int']>;
};

export type ArchiveRequestInput = {
  id: Scalars['ID'];
  unarchive?: Maybe<Scalars['Boolean']>;
};

export type AssignRequestInput = {
  id: Scalars['ID'];
  assignedTo: Scalars['ID'];
};

export type AwardcoBalanceResponse = {
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Int']>;
  currencyCode?: Maybe<Scalars['String']>;
};

export type AwardcoExistResponse = {
  success: Scalars['Boolean'];
  exists?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type AwardcoResponse = {
  success: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type BatchAddMemberInitiativeInput = {
  name: Scalars['String'];
  hasPast: Scalars['Boolean'];
};

export type Company = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tenure?: Maybe<Scalars['Int']>;
  address?: Maybe<Address>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
};

export type CompanyInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tenure?: Maybe<Scalars['Int']>;
  address?: Maybe<AddressInput>;
};

export type CompleteOnboardingInput = {
  expertises: Array<CreateExpertiseInput>;
  initiatives: Array<BatchAddMemberInitiativeInput>;
};

export type CreateEventLogInput = {
  type: EventLogType;
  metadata?: Maybe<Scalars['String']>;
};

export type CreateExpertiseInput = {
  name: Scalars['String'];
  type: ExpertiseType;
};

export type CreateExpertisesInput = {
  expertises?: Maybe<Array<CreateExpertiseInput>>;
};

export type CreateInitiativeInput = {
  name: Scalars['String'];
};

export type CreateInitiativesInput = {
  names: Array<Scalars['String']>;
};

export type CreateMemberNoteInput = {
  memberId: Scalars['ID'];
  message: Scalars['String'];
};

export type CreateRequestInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  serviceType?: Maybe<Scalars['String']>;
  phase?: Maybe<Phase>;
  category?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<TagInput>>;
  userId?: Maybe<Scalars['ID']>;
};

export type CreateRequestNoteInput = {
  requestId: Scalars['ID'];
  message: Scalars['String'];
};

export enum DateRange {
  OneMonthAgo = 'ONE_MONTH_AGO',
  ThreeMonthsAgo = 'THREE_MONTHS_AGO',
  OneYearAgo = 'ONE_YEAR_AGO',
  ThisMonth = 'THIS_MONTH',
  LastSevenDays = 'LAST_SEVEN_DAYS',
  LastMonth = 'LAST_MONTH',
  LastThreeMonths = 'LAST_THREE_MONTHS',
  ThisYear = 'THIS_YEAR',
  LastYear = 'LAST_YEAR',
  AllDates = 'ALL_DATES'
}

export type DuplicateRequestInput = {
  id: Scalars['ID'];
};

export type EventLog = {
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<EventLogType>;
  userId?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSONObject']>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
  user?: Maybe<User>;
};

export enum EventLogType {
  Login = 'LOGIN',
  CreatedUpcomingInitiative = 'CREATED_UPCOMING_INITIATIVE',
  CreatedRequest = 'CREATED_REQUEST',
  UpdatedAccount = 'UPDATED_ACCOUNT',
  AccountCreated = 'ACCOUNT_CREATED'
}

export type Expertise = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ExpertiseType>;
};

export enum ExpertiseType {
  Domain = 'DOMAIN',
  SubjectMatter = 'SUBJECT_MATTER',
  TechnologyProvider = 'TECHNOLOGY_PROVIDER'
}


export type GroupedEventLog = {
  createdAtDate?: Maybe<Scalars['GraphQLDateTime']>;
  userId?: Maybe<Scalars['ID']>;
  eventIds?: Maybe<Array<Scalars['ID']>>;
  count?: Maybe<Scalars['Int']>;
  events?: Maybe<Array<EventLog>>;
  user?: Maybe<User>;
};

export type Guest = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
};

export type GuestInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  company: CompanyInput;
};

export type Initiative = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type InviteMemberInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};


export type MemberSuggestions = {
  names: Array<Scalars['String']>;
  initiatives: Array<Initiative>;
  expertises: Array<Expertise>;
};

export type Mutation = {
  _empty?: Maybe<Scalars['String']>;
  createReferral?: Maybe<Referral>;
  updateReferral?: Maybe<Referral>;
  addAdvisorRoleToUser?: Maybe<UserAdvisorRole>;
  removeAdvisorRoleFromUser?: Maybe<Scalars['String']>;
  createUserAdvisor?: Maybe<User>;
  updateAdvisorCV?: Maybe<User>;
  createAwardcoUser: AwardcoResponse;
  rewardAwardcoUser: AwardcoResponse;
  archiveAwardcoUser: AwardcoResponse;
  createEventLog?: Maybe<Scalars['Boolean']>;
  createExpertise?: Maybe<Expertise>;
  createExpertises?: Maybe<Scalars['Boolean']>;
  addMemberExpertise?: Maybe<Scalars['Boolean']>;
  removeMemberExpertise?: Maybe<Scalars['Boolean']>;
  updateMemberExpertises?: Maybe<Scalars['Boolean']>;
  createInitiative?: Maybe<Initiative>;
  createInitiatives?: Maybe<Scalars['Boolean']>;
  addMemberInitiative?: Maybe<UserInitiative>;
  removeMemberInitiative?: Maybe<Scalars['Boolean']>;
  updateMemberInitiative?: Maybe<UserInitiative>;
  updateMemberInitiatives?: Maybe<Scalars['Boolean']>;
  createMemberNote?: Maybe<Note>;
  createRequestNote?: Maybe<Note>;
  completeOnboarding?: Maybe<Scalars['Boolean']>;
  addRequestPeer?: Maybe<Request>;
  removeRequestPeer?: Maybe<Request>;
  addRequestGuestPeer?: Maybe<Request>;
  removeRequestGuestPeer?: Maybe<Request>;
  register?: Maybe<User>;
  createRequest?: Maybe<Request>;
  updateRequest?: Maybe<Request>;
  archiveRequest?: Maybe<Request>;
  assignRequest?: Maybe<Request>;
  updateRequestStatus?: Maybe<Request>;
  scheduleRequest?: Maybe<Request>;
  duplicateRequest?: Maybe<Request>;
  updateMe?: Maybe<User>;
  inviteMember?: Maybe<Scalars['Boolean']>;
  updateMember?: Maybe<User>;
  updateMemberPhoto?: Maybe<Scalars['Boolean']>;
  updateMemberCV?: Maybe<User>;
  updateMemberAdvisorStatus?: Maybe<User>;
  addAdmin?: Maybe<User>;
  updateOnboarding?: Maybe<User>;
  checkEmail: Scalars['Boolean'];
  checkPhoneNumber: Scalars['Boolean'];
  syncEmail: Scalars['Boolean'];
  deleteMember: Scalars['Boolean'];
};


export type MutationCreateReferralArgs = {
  input: ReferralInput;
};


export type MutationUpdateReferralArgs = {
  referralId: Scalars['ID'];
  input: ReferralInput;
};


export type MutationAddAdvisorRoleToUserArgs = {
  input: UserAdvisorRoleInput;
};


export type MutationRemoveAdvisorRoleFromUserArgs = {
  input: UserAdvisorRoleInput;
};


export type MutationCreateUserAdvisorArgs = {
  referrals?: Maybe<Array<ReferralInput>>;
  advisorRoles?: Maybe<Array<Scalars['ID']>>;
  cv: Scalars['String'];
};


export type MutationUpdateAdvisorCvArgs = {
  input: AdvisorCvInput;
};


export type MutationCreateAwardcoUserArgs = {
  input: CreateAwardcoUserInput;
};


export type MutationRewardAwardcoUserArgs = {
  input: RewardAwardcoUserInput;
};


export type MutationArchiveAwardcoUserArgs = {
  employeeId: Scalars['String'];
};


export type MutationCreateEventLogArgs = {
  input: CreateEventLogInput;
};


export type MutationCreateExpertiseArgs = {
  input: CreateExpertiseInput;
};


export type MutationCreateExpertisesArgs = {
  input: CreateExpertisesInput;
};


export type MutationAddMemberExpertiseArgs = {
  input: AddMemberExpertiseInput;
};


export type MutationRemoveMemberExpertiseArgs = {
  input: RemoveMemberExpertiseInput;
};


export type MutationUpdateMemberExpertisesArgs = {
  input: UpdateMemberExpertisesInput;
};


export type MutationCreateInitiativeArgs = {
  input: CreateInitiativeInput;
};


export type MutationCreateInitiativesArgs = {
  input: CreateInitiativesInput;
};


export type MutationAddMemberInitiativeArgs = {
  input: AddMemberInitiativeInput;
};


export type MutationRemoveMemberInitiativeArgs = {
  input: RemoveMemberInitiativeInput;
};


export type MutationUpdateMemberInitiativeArgs = {
  input: UpdateMemberInitiativeInput;
};


export type MutationUpdateMemberInitiativesArgs = {
  input: UpdateMemberInitiativesInput;
};


export type MutationCreateMemberNoteArgs = {
  input: CreateMemberNoteInput;
};


export type MutationCreateRequestNoteArgs = {
  input: CreateRequestNoteInput;
};


export type MutationCompleteOnboardingArgs = {
  input: CompleteOnboardingInput;
};


export type MutationAddRequestPeerArgs = {
  input: AddRequestPeerInput;
};


export type MutationRemoveRequestPeerArgs = {
  input: RemoveRequestPeerInput;
};


export type MutationAddRequestGuestPeerArgs = {
  input: AddRequestGuestPeerInput;
};


export type MutationRemoveRequestGuestPeerArgs = {
  input: RemoveRequestGuestPeerInput;
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationCreateRequestArgs = {
  input: CreateRequestInput;
};


export type MutationUpdateRequestArgs = {
  input: UpdateRequestInput;
};


export type MutationArchiveRequestArgs = {
  input: ArchiveRequestInput;
};


export type MutationAssignRequestArgs = {
  input: AssignRequestInput;
};


export type MutationUpdateRequestStatusArgs = {
  input: UpdateRequestStatusInput;
};


export type MutationScheduleRequestArgs = {
  input: ScheduleRequestInput;
};


export type MutationDuplicateRequestArgs = {
  input: DuplicateRequestInput;
};


export type MutationUpdateMeArgs = {
  input: UpdateMeInput;
};


export type MutationInviteMemberArgs = {
  input: InviteMemberInput;
};


export type MutationUpdateMemberArgs = {
  input: UpdateMemberInput;
};


export type MutationUpdateMemberPhotoArgs = {
  input: UpdateMemberPhotoInput;
};


export type MutationUpdateMemberCvArgs = {
  input: UpdateMemberCv;
};


export type MutationUpdateMemberAdvisorStatusArgs = {
  input: UpdateMemberAdvisorStatusInput;
};


export type MutationAddAdminArgs = {
  input: AddAdminInput;
};


export type MutationUpdateOnboardingArgs = {
  enabled: Scalars['Boolean'];
};


export type MutationCheckEmailArgs = {
  email: Scalars['String'];
};


export type MutationCheckPhoneNumberArgs = {
  phoneNumber: Scalars['String'];
};


export type MutationSyncEmailArgs = {
  email: Scalars['String'];
};


export type MutationDeleteMemberArgs = {
  id: Scalars['ID'];
};

export type Note = {
  id?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['GraphQLDateTime']>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
  user?: Maybe<User>;
};

export enum Order {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OrderBy = {
  column: Scalars['String'];
  order?: Maybe<Order>;
};

export type PagedEventLog = {
  results?: Maybe<Array<EventLog>>;
  total?: Maybe<Scalars['Int']>;
};

export type PagedExpertise = {
  results?: Maybe<Array<Expertise>>;
  total?: Maybe<Scalars['Int']>;
};

export type PagedGroupedEventLog = {
  results?: Maybe<Array<GroupedEventLog>>;
  total?: Maybe<Scalars['Int']>;
};

export type PagedInitiative = {
  results?: Maybe<Array<Initiative>>;
  total?: Maybe<Scalars['Int']>;
};

export type PagedNotes = {
  results?: Maybe<Array<Note>>;
  total?: Maybe<Scalars['Int']>;
};

export type PagedRequests = {
  results?: Maybe<Array<Request>>;
  total?: Maybe<Scalars['Int']>;
};

export type PagedUserExpertise = {
  results?: Maybe<Array<UserExpertise>>;
  total?: Maybe<Scalars['Int']>;
};

export type PagedUsers = {
  results?: Maybe<Array<User>>;
  total?: Maybe<Scalars['Int']>;
};

export enum Phase {
  Intake = 'INTAKE',
  Discovery = 'DISCOVERY',
  Execution = 'EXECUTION',
  BenefitsRealization = 'BENEFITS_REALIZATION',
  Other = 'OTHER'
}

export type Query = {
  _empty?: Maybe<Scalars['String']>;
  advisorRoles?: Maybe<Array<Maybe<AdvisorRole>>>;
  advisorRole?: Maybe<AdvisorRole>;
  getUserAdvisorRole?: Maybe<UserAdvisorRole>;
  getUserAdvisorRoles?: Maybe<UserAdvisorRoles>;
  getReferralsByUserId?: Maybe<Array<Maybe<Referral>>>;
  getUserAdvisors?: Maybe<PagedUsers>;
  analytics?: Maybe<Analytics>;
  awardcoUserBalance?: Maybe<AwardcoBalanceResponse>;
  awardcoUserExists?: Maybe<AwardcoExistResponse>;
  eventLogs?: Maybe<PagedEventLog>;
  groupedEventLogs?: Maybe<PagedGroupedEventLog>;
  expertises?: Maybe<PagedExpertise>;
  memberExpertises?: Maybe<Array<Expertise>>;
  initiatives?: Maybe<PagedInitiative>;
  memberInitiatives?: Maybe<Array<UserInitiative>>;
  memberNotes?: Maybe<PagedNotes>;
  requestNotes?: Maybe<PagedNotes>;
  request?: Maybe<Request>;
  requests?: Maybe<PagedRequests>;
  requestSuggestions?: Maybe<RequestSuggestions>;
  suggestedTags?: Maybe<Array<Tag>>;
  me?: Maybe<User>;
  users?: Maybe<Array<User>>;
  admins?: Maybe<PagedUsers>;
  member?: Maybe<User>;
  members?: Maybe<PagedUsers>;
  memberSuggestions?: Maybe<MemberSuggestions>;
  memberAdvisors?: Maybe<PagedUsers>;
};


export type QueryAdvisorRoleArgs = {
  advisorRoleId: Scalars['ID'];
};


export type QueryGetUserAdvisorRoleArgs = {
  userAdvisorRoleId: Scalars['ID'];
};


export type QueryGetUserAdvisorRolesArgs = {
  userId: Scalars['ID'];
};


export type QueryGetReferralsByUserIdArgs = {
  userId?: Maybe<Scalars['ID']>;
};


export type QueryGetUserAdvisorsArgs = {
  filter?: Maybe<Array<Scalars['ID']>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderBy>;
};


export type QueryAnalyticsArgs = {
  dateRange: DateRange;
};


export type QueryAwardcoUserBalanceArgs = {
  employeeId: Scalars['String'];
};


export type QueryAwardcoUserExistsArgs = {
  employeeId: Scalars['String'];
};


export type QueryEventLogsArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  type?: Maybe<EventLogType>;
};


export type QueryGroupedEventLogsArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  dateRange?: Maybe<DateRange>;
};


export type QueryExpertisesArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  type?: Maybe<ExpertiseType>;
};


export type QueryMemberExpertisesArgs = {
  id: Scalars['ID'];
};


export type QueryInitiativesArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryMemberInitiativesArgs = {
  userId: Scalars['ID'];
  hasPast?: Maybe<Scalars['Boolean']>;
};


export type QueryMemberNotesArgs = {
  memberId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryRequestNotesArgs = {
  requestId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryRequestArgs = {
  id: Scalars['ID'];
};


export type QueryRequestsArgs = {
  status?: Maybe<Status>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['ID']>;
  peerId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  filter?: Maybe<Array<RequestFilter>>;
};


export type QueryRequestSuggestionsArgs = {
  search: Scalars['String'];
};


export type QuerySuggestedTagsArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type QueryUsersArgs = {
  role?: Maybe<Role>;
  orderBy?: Maybe<OrderBy>;
};


export type QueryAdminsArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  orderBy?: Maybe<OrderBy>;
};


export type QueryMemberArgs = {
  id: Scalars['ID'];
};


export type QueryMembersArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  orderBy?: Maybe<OrderBy>;
  filter?: Maybe<Array<UserFilter>>;
  initiatives?: Maybe<Array<Scalars['String']>>;
  expertises?: Maybe<Array<Scalars['String']>>;
  isAdvisor?: Maybe<Scalars['Boolean']>;
};


export type QueryMemberSuggestionsArgs = {
  search: Scalars['String'];
};


export type QueryMemberAdvisorsArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type Referral = {
  id?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  relationship?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type ReferralInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  relationship: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type RegisterInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  contactEmail?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
  collectiveExperience?: Maybe<Scalars['Int']>;
  company?: Maybe<CompanyInput>;
  hasTempPassword?: Maybe<Scalars['Boolean']>;
};

export type RemoveMemberExpertiseInput = {
  userId: Scalars['ID'];
  expertiseId: Scalars['ID'];
};

export type RemoveMemberInitiativeInput = {
  userId: Scalars['ID'];
  initiativeId: Scalars['ID'];
};

export type RemoveRequestGuestPeerInput = {
  requestId: Scalars['ID'];
};

export type RemoveRequestPeerInput = {
  requestId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type Request = {
  peerId?: Maybe<Scalars['ID']>;
  peerAddedAt?: Maybe<Scalars['GraphQLDateTime']>;
  peer?: Maybe<User>;
  guest?: Maybe<Guest>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  assignedTo?: Maybe<Scalars['ID']>;
  serviceType?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  phase?: Maybe<Phase>;
  status?: Maybe<Status>;
  tags?: Maybe<Array<Tag>>;
  scheduledAt?: Maybe<Scalars['GraphQLDateTime']>;
  archivedAt?: Maybe<Scalars['GraphQLDateTime']>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
  lastViewed?: Maybe<Scalars['GraphQLDateTime']>;
  user?: Maybe<User>;
  assignee?: Maybe<User>;
};

export enum RequestFilter {
  RecentlyViewed = 'recently_viewed',
  Assigned = 'assigned',
  Unassigned = 'unassigned',
  Active = 'active',
  Scheduled = 'scheduled',
  Unscheduled = 'unscheduled',
  Completed = 'completed',
  Archived = 'archived',
  Cancelled = 'cancelled',
  Matched = 'matched'
}

export type RequestSuggestions = {
  general: Array<Scalars['String']>;
  tags: Array<Scalars['String']>;
};

export enum Role {
  Admin = 'ADMIN',
  Member = 'MEMBER'
}

export type ScheduleRequestInput = {
  id: Scalars['ID'];
  scheduledAt?: Maybe<Scalars['String']>;
};

export type SocialMedia = {
  linkedIn?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
};

export type SocialMediaInput = {
  linkedIn?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
};

export enum Status {
  Received = 'RECEIVED',
  Active = 'ACTIVE',
  Scheduled = 'SCHEDULED',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING'
}

export type Tag = {
  name?: Maybe<Scalars['String']>;
};

export type TagInput = {
  name?: Maybe<Scalars['String']>;
};

export type UpdateMeInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
};

export type UpdateMemberAdvisorStatusInput = {
  isAdvisor: Scalars['Boolean'];
};

export type UpdateMemberCv = {
  cvURL: Scalars['String'];
};

export type UpdateMemberExpertisesInput = {
  userId: Scalars['ID'];
  type: ExpertiseType;
  names: Array<Scalars['String']>;
};

export type UpdateMemberInitiativeInput = {
  userId: Scalars['ID'];
  initiativeId: Scalars['ID'];
  hasPast: Scalars['Boolean'];
};

export type UpdateMemberInitiativesInput = {
  userId: Scalars['ID'];
  hasPast: Scalars['Boolean'];
  names: Array<Scalars['String']>;
};

export type UpdateMemberInput = {
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  contactEmail?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  collectiveExperience?: Maybe<Scalars['Int']>;
  socialMedia?: Maybe<SocialMediaInput>;
  company: CompanyInput;
};

export type UpdateMemberPhotoInput = {
  id: Scalars['ID'];
  photoURL: Scalars['String'];
};

export type UpdateRequestInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  serviceType?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  phase?: Maybe<Phase>;
  tags?: Maybe<Array<TagInput>>;
};

export type UpdateRequestStatusInput = {
  id: Scalars['ID'];
  status: Status;
};

export type User = {
  referrals?: Maybe<Array<Maybe<Referral>>>;
  company?: Maybe<Company>;
  expertises?: Maybe<Array<Expertise>>;
  userExpertises?: Maybe<PagedUserExpertise>;
  initiatives?: Maybe<Array<UserInitiative>>;
  assignedRequestCount?: Maybe<Scalars['Int']>;
  requestCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  collectiveExperience?: Maybe<Scalars['Int']>;
  role?: Maybe<Role>;
  companyId?: Maybe<Scalars['ID']>;
  socialMedia?: Maybe<SocialMedia>;
  showOnboarding?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
  lastLoggedIn?: Maybe<Scalars['GraphQLDateTime']>;
  lastViewed?: Maybe<Scalars['GraphQLDateTime']>;
  isAdvisor?: Maybe<Scalars['Boolean']>;
  advisorUpdatedDate?: Maybe<Scalars['GraphQLDateTime']>;
  cv?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  lastLoggedInFirebase?: Maybe<Scalars['GraphQLDateTime']>;
  profileStrength?: Maybe<Scalars['Int']>;
};


export type UserUserExpertisesArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  type?: Maybe<ExpertiseType>;
};


export type UserInitiativesArgs = {
  hasPast?: Maybe<Scalars['Boolean']>;
};


export type UserRequestCountArgs = {
  status?: Maybe<Status>;
};

export type UserAdvisorRole = {
  id?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['String']>;
  advisorRoleId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  advisorRole?: Maybe<AdvisorRole>;
};

export type UserAdvisorRoleInput = {
  userId: Scalars['String'];
  advisorRoleId: Scalars['String'];
};

export type UserAdvisorRoles = {
  userId?: Maybe<Scalars['ID']>;
  roles?: Maybe<Array<Maybe<AdvisorRole>>>;
};

export type UserExpertise = {
  id?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
  expertiseId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
  expertise?: Maybe<Expertise>;
};

export enum UserFilter {
  New = 'new',
  RecentlyViewed = 'recently_viewed'
}

export type UserInitiative = {
  id?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
  initiativeId?: Maybe<Scalars['ID']>;
  hasPast?: Maybe<Scalars['Boolean']>;
  initiative?: Maybe<Initiative>;
};

export type ArchiveAwardcoUserInput = {
  employeeId: Scalars['String'];
};

export type CreateAwardcoUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  employeeId: Scalars['String'];
};

export type RewardAwardcoUserInput = {
  employeeId: Scalars['String'];
  amount: Scalars['Int'];
  note: Scalars['String'];
  rewardedBy: Scalars['String'];
  emailTemplate: Scalars['String'];
};

export type AddRequestGuestMutationVariables = Exact<{
  input: AddRequestGuestPeerInput;
}>;


export type AddRequestGuestMutation = { addRequestGuestPeer?: Maybe<(
    Pick<Request, 'id' | 'peerAddedAt'>
    & { guest?: Maybe<Pick<Guest, 'firstName' | 'lastName' | 'email' | 'phoneNumber'>> }
  )> };

export type RewardAwardcoUserMutationVariables = Exact<{
  input: RewardAwardcoUserInput;
}>;


export type RewardAwardcoUserMutation = { rewardAwardcoUser: Pick<AwardcoResponse, 'success' | 'message'> };

export type AddRoleToUserMutationVariables = Exact<{
  input: UserAdvisorRoleInput;
}>;


export type AddRoleToUserMutation = { addAdvisorRoleToUser?: Maybe<Pick<UserAdvisorRole, 'id' | 'userId' | 'advisorRoleId'>> };

export type CreateReferralMutationVariables = Exact<{
  input: ReferralInput;
}>;


export type CreateReferralMutation = { createReferral?: Maybe<Pick<Referral, 'id' | 'userId' | 'firstName' | 'relationship' | 'phoneNumber' | 'email'>> };

export type CreateUserAdvisorMutationVariables = Exact<{
  referrals?: Maybe<Array<ReferralInput> | ReferralInput>;
  advisorRoles?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  cv: Scalars['String'];
}>;


export type CreateUserAdvisorMutation = { createUserAdvisor?: Maybe<(
    Pick<User, 'id' | 'firstName' | 'lastName' | 'isAdvisor' | 'cv'>
    & { referrals?: Maybe<Array<Maybe<Pick<Referral, 'id' | 'lastName' | 'firstName' | 'email' | 'phoneNumber'>>>> }
  )> };

export type GetAdvisorReferralsQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type GetAdvisorReferralsQuery = { getReferralsByUserId?: Maybe<Array<Maybe<Pick<Referral, 'id' | 'lastName' | 'firstName' | 'phoneNumber' | 'userId' | 'email'>>>> };

export type GetAdvisorRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdvisorRolesQuery = { advisorRoles?: Maybe<Array<Maybe<Pick<AdvisorRole, 'id' | 'advisorRole'>>>> };

export type GetUserAdvisorRolesQueryVariables = Exact<{
  input: Scalars['ID'];
}>;


export type GetUserAdvisorRolesQuery = { getUserAdvisorRoles?: Maybe<(
    Pick<UserAdvisorRoles, 'userId'>
    & { roles?: Maybe<Array<Maybe<Pick<AdvisorRole, 'id' | 'advisorRole'>>>> }
  )> };

export type RemoveAdvisorRoleFromUserMutationVariables = Exact<{
  input: UserAdvisorRoleInput;
}>;


export type RemoveAdvisorRoleFromUserMutation = Pick<Mutation, 'removeAdvisorRoleFromUser'>;

export type UpdateAdvisorCvMutationVariables = Exact<{
  input: AdvisorCvInput;
}>;


export type UpdateAdvisorCvMutation = { updateAdvisorCV?: Maybe<Pick<User, 'id' | 'firstName' | 'lastName' | 'cv'>> };

export type UpdateReferralMutationVariables = Exact<{
  referralId: Scalars['ID'];
  input: ReferralInput;
}>;


export type UpdateReferralMutation = { updateReferral?: Maybe<Pick<Referral, 'id' | 'userId' | 'firstName' | 'relationship' | 'phoneNumber' | 'email'>> };

export type CreateRequestMutationVariables = Exact<{
  input: CreateRequestInput;
}>;


export type CreateRequestMutation = { createRequest?: Maybe<Pick<Request, 'id'>> };

export type ListInitiativesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;


export type ListInitiativesQuery = { initiatives?: Maybe<{ results?: Maybe<Array<Pick<Initiative, 'id' | 'name'>>> }> };

export type CreateMemberNoteMutationVariables = Exact<{
  input: CreateMemberNoteInput;
}>;


export type CreateMemberNoteMutation = { createMemberNote?: Maybe<(
    Pick<Note, 'id' | 'message' | 'createdAt' | 'updatedAt'>
    & { user?: Maybe<Pick<User, 'id' | 'picture' | 'firstName' | 'lastName'>> }
  )> };

export type CreateRequestNoteMutationVariables = Exact<{
  input: CreateRequestNoteInput;
}>;


export type CreateRequestNoteMutation = { createRequestNote?: Maybe<(
    Pick<Note, 'id' | 'message' | 'createdAt' | 'updatedAt'>
    & { user?: Maybe<Pick<User, 'id' | 'picture' | 'firstName' | 'lastName'>> }
  )> };

export type ListMemberNotesQueryVariables = Exact<{
  memberId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;


export type ListMemberNotesQuery = { memberNotes?: Maybe<(
    Pick<PagedNotes, 'total'>
    & { results?: Maybe<Array<(
      Pick<Note, 'id' | 'message' | 'createdAt' | 'updatedAt'>
      & { user?: Maybe<Pick<User, 'id' | 'picture' | 'firstName' | 'lastName'>> }
    )>> }
  )> };

export type ListRequestNotesQueryVariables = Exact<{
  requestId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;


export type ListRequestNotesQuery = { requestNotes?: Maybe<(
    Pick<PagedNotes, 'total'>
    & { results?: Maybe<Array<(
      Pick<Note, 'id' | 'message' | 'createdAt' | 'updatedAt'>
      & { user?: Maybe<Pick<User, 'id' | 'picture' | 'firstName' | 'lastName'>> }
    )>> }
  )> };

export type DuplicateRequestMutationVariables = Exact<{
  input: DuplicateRequestInput;
}>;


export type DuplicateRequestMutation = { duplicateRequest?: Maybe<Pick<Request, 'id'>> };

export type RemoveRequestGuestMutationVariables = Exact<{
  input: RemoveRequestGuestPeerInput;
}>;


export type RemoveRequestGuestMutation = { removeRequestGuestPeer?: Maybe<(
    Pick<Request, 'id' | 'peerAddedAt'>
    & { guest?: Maybe<Pick<Guest, 'firstName' | 'lastName'>> }
  )> };

export type RemoveRequestPeerMutationVariables = Exact<{
  input: RemoveRequestPeerInput;
}>;


export type RemoveRequestPeerMutation = { removeRequestPeer?: Maybe<Pick<Request, 'id'>> };

export type ScheduleRequestMutationVariables = Exact<{
  input: ScheduleRequestInput;
}>;


export type ScheduleRequestMutation = { scheduleRequest?: Maybe<Pick<Request, 'id' | 'scheduledAt'>> };

export type AssignRequestMutationVariables = Exact<{
  input: AssignRequestInput;
}>;


export type AssignRequestMutation = { assignRequest?: Maybe<(
    Pick<Request, 'id' | 'assignedTo'>
    & { assignee?: Maybe<Pick<User, 'firstName' | 'lastName'>> }
  )> };

export type ListAdminsSelectionQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAdminsSelectionQuery = { users?: Maybe<Array<Pick<User, 'id' | 'firstName' | 'lastName'>>> };

export type ListSuggestedTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListSuggestedTagsQuery = { suggestedTags?: Maybe<Array<Pick<Tag, 'name'>>> };

export type GetUpdateRequestQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUpdateRequestQuery = { request?: Maybe<(
    Pick<Request, 'name' | 'serviceType' | 'phase' | 'category' | 'description'>
    & { tags?: Maybe<Array<Pick<Tag, 'name'>>> }
  )> };

export type UpdateRequestMutationVariables = Exact<{
  input: UpdateRequestInput;
}>;


export type UpdateRequestMutation = { updateRequest?: Maybe<(
    Pick<Request, 'id' | 'name' | 'serviceType' | 'phase' | 'category' | 'description'>
    & { tags?: Maybe<Array<Pick<Tag, 'name'>>> }
  )> };

export type ArchiveRequestMutationVariables = Exact<{
  input: ArchiveRequestInput;
}>;


export type ArchiveRequestMutation = { archiveRequest?: Maybe<Pick<Request, 'id' | 'archivedAt'>> };

export type GetViewRequestQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetViewRequestQuery = { request?: Maybe<(
    Pick<Request, 'id' | 'name' | 'phase' | 'description' | 'serviceType' | 'category' | 'assignedTo' | 'archivedAt' | 'scheduledAt' | 'createdAt' | 'updatedAt' | 'status' | 'peerId' | 'peerAddedAt'>
    & { assignee?: Maybe<Pick<User, 'firstName' | 'lastName'>>, tags?: Maybe<Array<Pick<Tag, 'name'>>>, user?: Maybe<(
      Pick<User, 'id' | 'firstName' | 'lastName' | 'picture'>
      & { company?: Maybe<(
        Pick<Company, 'title' | 'name'>
        & { address?: Maybe<Pick<Address, 'streetAddress' | 'locality' | 'region' | 'postalCode'>> }
      )> }
    )>, peer?: Maybe<Pick<User, 'id' | 'picture' | 'firstName' | 'lastName'>>, guest?: Maybe<(
      Pick<Guest, 'firstName' | 'lastName' | 'phoneNumber' | 'email'>
      & { company?: Maybe<Pick<Company, 'name' | 'title'>> }
    )> }
  )> };

export type UpdateRequestStatusMutationVariables = Exact<{
  input: UpdateRequestStatusInput;
}>;


export type UpdateRequestStatusMutation = { updateRequestStatus?: Maybe<Pick<Request, 'id' | 'status'>> };

export type SelfQueryVariables = Exact<{ [key: string]: never; }>;


export type SelfQuery = { me?: Maybe<Pick<User, 'id' | 'firstName' | 'lastName' | 'role' | 'email' | 'picture' | 'showOnboarding'>> };

export type SyncEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SyncEmailMutation = Pick<Mutation, 'syncEmail'>;

export type UpdateOnboardingMutationVariables = Exact<{
  enabled: Scalars['Boolean'];
}>;


export type UpdateOnboardingMutation = { updateOnboarding?: Maybe<Pick<User, 'showOnboarding'>> };

export type CreateEventLogMutationVariables = Exact<{
  input: CreateEventLogInput;
}>;


export type CreateEventLogMutation = Pick<Mutation, 'createEventLog'>;

export type ListNewRequestsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  filter?: Maybe<Array<RequestFilter> | RequestFilter>;
}>;


export type ListNewRequestsQuery = { requests?: Maybe<(
    Pick<PagedRequests, 'total'>
    & { results?: Maybe<Array<(
      Pick<Request, 'id' | 'name' | 'phase' | 'description' | 'serviceType' | 'category' | 'scheduledAt' | 'createdAt' | 'updatedAt' | 'status'>
      & { tags?: Maybe<Array<Pick<Tag, 'name'>>>, user?: Maybe<(
        Pick<User, 'id' | 'firstName' | 'lastName' | 'picture'>
        & { company?: Maybe<(
          Pick<Company, 'title' | 'name'>
          & { address?: Maybe<Pick<Address, 'streetAddress' | 'locality' | 'region' | 'postalCode'>> }
        )> }
      )> }
    )>> }
  )> };

export type UpdateMemberExpertisesMutationVariables = Exact<{
  input: UpdateMemberExpertisesInput;
}>;


export type UpdateMemberExpertisesMutation = Pick<Mutation, 'updateMemberExpertises'>;

export type UpdateMemberInitiativesMutationVariables = Exact<{
  input: UpdateMemberInitiativesInput;
}>;


export type UpdateMemberInitiativesMutation = Pick<Mutation, 'updateMemberInitiatives'>;

export type UpdateSelfMutationVariables = Exact<{
  input: UpdateMeInput;
}>;


export type UpdateSelfMutation = { updateMe?: Maybe<Pick<User, 'id'>> };

export type AddMemberMutationVariables = Exact<{
  input: RegisterInput;
}>;


export type AddMemberMutation = { register?: Maybe<(
    Pick<User, 'id' | 'firstName' | 'lastName' | 'picture' | 'updatedAt' | 'lastLoggedIn'>
    & { company?: Maybe<(
      Pick<Company, 'name' | 'title'>
      & { address?: Maybe<Pick<Address, 'streetAddress' | 'locality' | 'region' | 'postalCode'>> }
    )> }
  )> };

export type InviteMemberMutationVariables = Exact<{
  input: InviteMemberInput;
}>;


export type InviteMemberMutation = Pick<Mutation, 'inviteMember'>;

export type AnalyticsQueryVariables = Exact<{
  dateRange: DateRange;
}>;


export type AnalyticsQuery = { analytics?: Maybe<Pick<Analytics, 'requests' | 'matches' | 'members'>> };

export type ListRecentActivityQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  dateRange?: Maybe<DateRange>;
}>;


export type ListRecentActivityQuery = { groupedEventLogs?: Maybe<(
    Pick<PagedGroupedEventLog, 'total'>
    & { results?: Maybe<Array<(
      Pick<GroupedEventLog, 'createdAtDate'>
      & { user?: Maybe<Pick<User, 'id' | 'firstName' | 'lastName' | 'picture'>>, events?: Maybe<Array<Pick<EventLog, 'id' | 'message' | 'type' | 'createdAt' | 'metadata'>>> }
    )>> }
  )> };

export type UpdateMemberMutationVariables = Exact<{
  input: UpdateMemberInput;
}>;


export type UpdateMemberMutation = { updateMember?: Maybe<(
    Pick<User, 'id' | 'firstName' | 'lastName' | 'picture' | 'collectiveExperience' | 'email' | 'contactEmail' | 'phoneNumber' | 'profileStrength' | 'createdAt' | 'updatedAt' | 'lastLoggedIn'>
    & { socialMedia?: Maybe<Pick<SocialMedia, 'linkedIn'>>, company?: Maybe<(
      Pick<Company, 'name' | 'title' | 'tenure'>
      & { address?: Maybe<Pick<Address, 'streetAddress' | 'locality' | 'region' | 'postalCode'>> }
    )> }
  )> };

export type CreateAwardcoUserMutationVariables = Exact<{
  input: CreateAwardcoUserInput;
}>;


export type CreateAwardcoUserMutation = { createAwardcoUser: Pick<AwardcoResponse, 'success' | 'message'> };

export type CreateExpertisesMutationVariables = Exact<{
  input: CreateExpertisesInput;
}>;


export type CreateExpertisesMutation = Pick<Mutation, 'createExpertises'>;

export type CreateInitiativesMutationVariables = Exact<{
  input: CreateInitiativesInput;
}>;


export type CreateInitiativesMutation = Pick<Mutation, 'createInitiatives'>;

export type DeleteMemberMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteMemberMutation = Pick<Mutation, 'deleteMember'>;

export type UserExistsQueryVariables = Exact<{
  employeeId: Scalars['String'];
}>;


export type UserExistsQuery = { awardcoUserExists?: Maybe<Pick<AwardcoExistResponse, 'success' | 'exists' | 'message'>> };

export type UserBalanceQueryVariables = Exact<{
  employeeId: Scalars['String'];
}>;


export type UserBalanceQuery = { awardcoUserBalance?: Maybe<Pick<AwardcoBalanceResponse, 'success' | 'message' | 'balance' | 'currencyCode'>> };

export type GetMemberQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetMemberQuery = { member?: Maybe<(
    Pick<User, 'id' | 'firstName' | 'lastName' | 'picture' | 'collectiveExperience' | 'email' | 'contactEmail' | 'phoneNumber' | 'profileStrength' | 'createdAt' | 'updatedAt' | 'lastLoggedIn' | 'isAdvisor' | 'advisorUpdatedDate' | 'cv'>
    & { expertises?: Maybe<Array<Pick<Expertise, 'id' | 'name' | 'type'>>>, initiatives?: Maybe<Array<(
      Pick<UserInitiative, 'id' | 'hasPast'>
      & { initiative?: Maybe<Pick<Initiative, 'id' | 'name'>> }
    )>>, company?: Maybe<(
      Pick<Company, 'name' | 'title' | 'tenure'>
      & { address?: Maybe<Pick<Address, 'streetAddress' | 'locality' | 'region' | 'postalCode'>> }
    )>, socialMedia?: Maybe<Pick<SocialMedia, 'linkedIn'>>, referrals?: Maybe<Array<Maybe<Pick<Referral, 'id' | 'lastName' | 'firstName' | 'email' | 'phoneNumber' | 'relationship'>>>> }
  )> };

export type ListMemberRequestsQueryVariables = Exact<{
  status?: Maybe<Status>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['ID']>;
  peerId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  filter?: Maybe<Array<RequestFilter> | RequestFilter>;
}>;


export type ListMemberRequestsQuery = { requests?: Maybe<(
    Pick<PagedRequests, 'total'>
    & { results?: Maybe<Array<Pick<Request, 'id' | 'name' | 'serviceType' | 'phase' | 'category' | 'status' | 'archivedAt' | 'scheduledAt' | 'createdAt' | 'updatedAt'>>> }
  )> };

export type RequestSuggestionsQueryVariables = Exact<{
  search: Scalars['String'];
}>;


export type RequestSuggestionsQuery = { requestSuggestions?: Maybe<Pick<RequestSuggestions, 'general' | 'tags'>> };

export type ListAdminsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  orderBy?: Maybe<OrderBy>;
}>;


export type ListAdminsQuery = { admins?: Maybe<(
    Pick<PagedUsers, 'total'>
    & { results?: Maybe<Array<Pick<User, 'id' | 'firstName' | 'lastName' | 'picture' | 'assignedRequestCount' | 'createdAt' | 'updatedAt' | 'lastLoggedIn'>>> }
  )> };

export type AddAdminMutationVariables = Exact<{
  input: AddAdminInput;
}>;


export type AddAdminMutation = { addAdmin?: Maybe<Pick<User, 'id'>> };

export type GetUserAdvisorsQueryVariables = Exact<{
  filter?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderBy>;
}>;


export type GetUserAdvisorsQuery = { getUserAdvisors?: Maybe<(
    Pick<PagedUsers, 'total'>
    & { results?: Maybe<Array<(
      Pick<User, 'id' | 'role' | 'firstName' | 'lastName' | 'advisorUpdatedDate' | 'picture' | 'cv'>
      & { referrals?: Maybe<Array<Maybe<Pick<Referral, 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'relationship'>>>> }
    )>> }
  )> };

export type ListMembersQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  orderBy?: Maybe<OrderBy>;
  filter?: Maybe<Array<UserFilter> | UserFilter>;
  initiatives?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  expertises?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type ListMembersQuery = { members?: Maybe<(
    Pick<PagedUsers, 'total'>
    & { results?: Maybe<Array<(
      Pick<User, 'id' | 'firstName' | 'lastName' | 'picture' | 'createdAt' | 'updatedAt' | 'lastLoggedIn'>
      & { totalRequestCount: User['requestCount'], receivedRequestCount: User['requestCount'], activeRequestCount: User['requestCount'], scheduledRequestCount: User['requestCount'] }
      & { company?: Maybe<(
        Pick<Company, 'name' | 'title'>
        & { address?: Maybe<Pick<Address, 'streetAddress' | 'locality' | 'region' | 'postalCode'>> }
      )>, domainExpertises?: Maybe<(
        Pick<PagedUserExpertise, 'total'>
        & { results?: Maybe<Array<(
          Pick<UserExpertise, 'createdAt'>
          & { expertise?: Maybe<Pick<Expertise, 'name'>> }
        )>> }
      )>, subjectMatterExpertises?: Maybe<(
        Pick<PagedUserExpertise, 'total'>
        & { results?: Maybe<Array<(
          Pick<UserExpertise, 'createdAt'>
          & { expertise?: Maybe<Pick<Expertise, 'name'>> }
        )>> }
      )>, technologyProviderExpertises?: Maybe<(
        Pick<PagedUserExpertise, 'total'>
        & { results?: Maybe<Array<(
          Pick<UserExpertise, 'createdAt'>
          & { expertise?: Maybe<Pick<Expertise, 'name'>> }
        )>> }
      )> }
    )>> }
  )> };

export type MemberSuggestionsQueryVariables = Exact<{
  search: Scalars['String'];
}>;


export type MemberSuggestionsQuery = { memberSuggestions?: Maybe<(
    Pick<MemberSuggestions, 'names'>
    & { initiatives: Array<Pick<Initiative, 'id' | 'name'>>, expertises: Array<Pick<Expertise, 'id' | 'name' | 'type'>> }
  )> };

export type GetMemberSelfQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMemberSelfQuery = { me?: Maybe<(
    Pick<User, 'id' | 'firstName' | 'lastName' | 'picture' | 'collectiveExperience' | 'email' | 'contactEmail' | 'phoneNumber' | 'profileStrength' | 'createdAt' | 'updatedAt' | 'lastLoggedIn' | 'isAdvisor' | 'advisorUpdatedDate' | 'cv'>
    & { expertises?: Maybe<Array<Pick<Expertise, 'id' | 'name' | 'type'>>>, initiatives?: Maybe<Array<(
      Pick<UserInitiative, 'id' | 'hasPast'>
      & { initiative?: Maybe<Pick<Initiative, 'id' | 'name'>> }
    )>>, company?: Maybe<(
      Pick<Company, 'name' | 'title' | 'tenure'>
      & { address?: Maybe<Pick<Address, 'streetAddress' | 'locality' | 'region' | 'postalCode'>> }
    )>, socialMedia?: Maybe<Pick<SocialMedia, 'linkedIn'>>, referrals?: Maybe<Array<Maybe<Pick<Referral, 'id' | 'lastName' | 'firstName' | 'email' | 'phoneNumber' | 'relationship'>>>> }
  )> };

export type UpdateMemberAdvisorStatusMutationVariables = Exact<{
  input: UpdateMemberAdvisorStatusInput;
}>;


export type UpdateMemberAdvisorStatusMutation = { updateMemberAdvisorStatus?: Maybe<Pick<User, 'isAdvisor' | 'advisorUpdatedDate'>> };

export type UpdateMemberPhotoMutationVariables = Exact<{
  input: UpdateMemberPhotoInput;
}>;


export type UpdateMemberPhotoMutation = Pick<Mutation, 'updateMemberPhoto'>;

export type CompleteOnboardingMutationVariables = Exact<{
  input: CompleteOnboardingInput;
}>;


export type CompleteOnboardingMutation = Pick<Mutation, 'completeOnboarding'>;

export type ListExpertisesQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  type?: Maybe<ExpertiseType>;
}>;


export type ListExpertisesQuery = { expertises?: Maybe<{ results?: Maybe<Array<Pick<Expertise, 'id' | 'name' | 'type'>>> }> };

export type AddRequestPeerMutationVariables = Exact<{
  input: AddRequestPeerInput;
}>;


export type AddRequestPeerMutation = { addRequestPeer?: Maybe<Pick<Request, 'id'>> };

export type CheckEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type CheckEmailMutation = Pick<Mutation, 'checkEmail'>;

export type CheckPhoneNumberMutationVariables = Exact<{
  phoneNumber: Scalars['String'];
}>;


export type CheckPhoneNumberMutation = Pick<Mutation, 'checkPhoneNumber'>;

export type SignUpMutationVariables = Exact<{
  input: RegisterInput;
}>;


export type SignUpMutation = { register?: Maybe<Pick<User, 'email'>> };


export const AddRequestGuestDocument = gql`
    mutation AddRequestGuest($input: AddRequestGuestPeerInput!) {
  addRequestGuestPeer(input: $input) {
    id
    guest {
      firstName
      lastName
      email
      phoneNumber
    }
    peerAddedAt
  }
}
    `;
export type AddRequestGuestMutationFn = ApolloReactCommon.MutationFunction<AddRequestGuestMutation, AddRequestGuestMutationVariables>;

/**
 * __useAddRequestGuestMutation__
 *
 * To run a mutation, you first call `useAddRequestGuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRequestGuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRequestGuestMutation, { data, loading, error }] = useAddRequestGuestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRequestGuestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddRequestGuestMutation, AddRequestGuestMutationVariables>) {
        return ApolloReactHooks.useMutation<AddRequestGuestMutation, AddRequestGuestMutationVariables>(AddRequestGuestDocument, baseOptions);
      }
export type AddRequestGuestMutationHookResult = ReturnType<typeof useAddRequestGuestMutation>;
export type AddRequestGuestMutationResult = ApolloReactCommon.MutationResult<AddRequestGuestMutation>;
export type AddRequestGuestMutationOptions = ApolloReactCommon.BaseMutationOptions<AddRequestGuestMutation, AddRequestGuestMutationVariables>;
export const RewardAwardcoUserDocument = gql`
    mutation RewardAwardcoUser($input: rewardAwardcoUserInput!) {
  rewardAwardcoUser(input: $input) {
    success
    message
  }
}
    `;
export type RewardAwardcoUserMutationFn = ApolloReactCommon.MutationFunction<RewardAwardcoUserMutation, RewardAwardcoUserMutationVariables>;

/**
 * __useRewardAwardcoUserMutation__
 *
 * To run a mutation, you first call `useRewardAwardcoUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRewardAwardcoUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rewardAwardcoUserMutation, { data, loading, error }] = useRewardAwardcoUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRewardAwardcoUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RewardAwardcoUserMutation, RewardAwardcoUserMutationVariables>) {
        return ApolloReactHooks.useMutation<RewardAwardcoUserMutation, RewardAwardcoUserMutationVariables>(RewardAwardcoUserDocument, baseOptions);
      }
export type RewardAwardcoUserMutationHookResult = ReturnType<typeof useRewardAwardcoUserMutation>;
export type RewardAwardcoUserMutationResult = ApolloReactCommon.MutationResult<RewardAwardcoUserMutation>;
export type RewardAwardcoUserMutationOptions = ApolloReactCommon.BaseMutationOptions<RewardAwardcoUserMutation, RewardAwardcoUserMutationVariables>;
export const AddRoleToUserDocument = gql`
    mutation addRoleToUser($input: UserAdvisorRoleInput!) {
  addAdvisorRoleToUser(input: $input) {
    id
    userId
    advisorRoleId
  }
}
    `;
export type AddRoleToUserMutationFn = ApolloReactCommon.MutationFunction<AddRoleToUserMutation, AddRoleToUserMutationVariables>;

/**
 * __useAddRoleToUserMutation__
 *
 * To run a mutation, you first call `useAddRoleToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRoleToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRoleToUserMutation, { data, loading, error }] = useAddRoleToUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRoleToUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddRoleToUserMutation, AddRoleToUserMutationVariables>) {
        return ApolloReactHooks.useMutation<AddRoleToUserMutation, AddRoleToUserMutationVariables>(AddRoleToUserDocument, baseOptions);
      }
export type AddRoleToUserMutationHookResult = ReturnType<typeof useAddRoleToUserMutation>;
export type AddRoleToUserMutationResult = ApolloReactCommon.MutationResult<AddRoleToUserMutation>;
export type AddRoleToUserMutationOptions = ApolloReactCommon.BaseMutationOptions<AddRoleToUserMutation, AddRoleToUserMutationVariables>;
export const CreateReferralDocument = gql`
    mutation createReferral($input: ReferralInput!) {
  createReferral(input: $input) {
    id
    userId
    firstName
    relationship
    phoneNumber
    email
  }
}
    `;
export type CreateReferralMutationFn = ApolloReactCommon.MutationFunction<CreateReferralMutation, CreateReferralMutationVariables>;

/**
 * __useCreateReferralMutation__
 *
 * To run a mutation, you first call `useCreateReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReferralMutation, { data, loading, error }] = useCreateReferralMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReferralMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateReferralMutation, CreateReferralMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateReferralMutation, CreateReferralMutationVariables>(CreateReferralDocument, baseOptions);
      }
export type CreateReferralMutationHookResult = ReturnType<typeof useCreateReferralMutation>;
export type CreateReferralMutationResult = ApolloReactCommon.MutationResult<CreateReferralMutation>;
export type CreateReferralMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateReferralMutation, CreateReferralMutationVariables>;
export const CreateUserAdvisorDocument = gql`
    mutation createUserAdvisor($referrals: [ReferralInput!], $advisorRoles: [ID!], $cv: String!) {
  createUserAdvisor(referrals: $referrals, advisorRoles: $advisorRoles, cv: $cv) {
    id
    firstName
    lastName
    referrals {
      id
      lastName
      firstName
      email
      phoneNumber
    }
    isAdvisor
    cv
  }
}
    `;
export type CreateUserAdvisorMutationFn = ApolloReactCommon.MutationFunction<CreateUserAdvisorMutation, CreateUserAdvisorMutationVariables>;

/**
 * __useCreateUserAdvisorMutation__
 *
 * To run a mutation, you first call `useCreateUserAdvisorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserAdvisorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserAdvisorMutation, { data, loading, error }] = useCreateUserAdvisorMutation({
 *   variables: {
 *      referrals: // value for 'referrals'
 *      advisorRoles: // value for 'advisorRoles'
 *      cv: // value for 'cv'
 *   },
 * });
 */
export function useCreateUserAdvisorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserAdvisorMutation, CreateUserAdvisorMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserAdvisorMutation, CreateUserAdvisorMutationVariables>(CreateUserAdvisorDocument, baseOptions);
      }
export type CreateUserAdvisorMutationHookResult = ReturnType<typeof useCreateUserAdvisorMutation>;
export type CreateUserAdvisorMutationResult = ApolloReactCommon.MutationResult<CreateUserAdvisorMutation>;
export type CreateUserAdvisorMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserAdvisorMutation, CreateUserAdvisorMutationVariables>;
export const GetAdvisorReferralsDocument = gql`
    query getAdvisorReferrals($userId: ID!) {
  getReferralsByUserId(userId: $userId) {
    id
    lastName
    firstName
    phoneNumber
    userId
    email
  }
}
    `;

/**
 * __useGetAdvisorReferralsQuery__
 *
 * To run a query within a React component, call `useGetAdvisorReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdvisorReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdvisorReferralsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAdvisorReferralsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAdvisorReferralsQuery, GetAdvisorReferralsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAdvisorReferralsQuery, GetAdvisorReferralsQueryVariables>(GetAdvisorReferralsDocument, baseOptions);
      }
export function useGetAdvisorReferralsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAdvisorReferralsQuery, GetAdvisorReferralsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAdvisorReferralsQuery, GetAdvisorReferralsQueryVariables>(GetAdvisorReferralsDocument, baseOptions);
        }
export type GetAdvisorReferralsQueryHookResult = ReturnType<typeof useGetAdvisorReferralsQuery>;
export type GetAdvisorReferralsLazyQueryHookResult = ReturnType<typeof useGetAdvisorReferralsLazyQuery>;
export type GetAdvisorReferralsQueryResult = ApolloReactCommon.QueryResult<GetAdvisorReferralsQuery, GetAdvisorReferralsQueryVariables>;
export const GetAdvisorRolesDocument = gql`
    query getAdvisorRoles {
  advisorRoles {
    id
    advisorRole
  }
}
    `;

/**
 * __useGetAdvisorRolesQuery__
 *
 * To run a query within a React component, call `useGetAdvisorRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdvisorRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdvisorRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdvisorRolesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAdvisorRolesQuery, GetAdvisorRolesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAdvisorRolesQuery, GetAdvisorRolesQueryVariables>(GetAdvisorRolesDocument, baseOptions);
      }
export function useGetAdvisorRolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAdvisorRolesQuery, GetAdvisorRolesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAdvisorRolesQuery, GetAdvisorRolesQueryVariables>(GetAdvisorRolesDocument, baseOptions);
        }
export type GetAdvisorRolesQueryHookResult = ReturnType<typeof useGetAdvisorRolesQuery>;
export type GetAdvisorRolesLazyQueryHookResult = ReturnType<typeof useGetAdvisorRolesLazyQuery>;
export type GetAdvisorRolesQueryResult = ApolloReactCommon.QueryResult<GetAdvisorRolesQuery, GetAdvisorRolesQueryVariables>;
export const GetUserAdvisorRolesDocument = gql`
    query getUserAdvisorRoles($input: ID!) {
  getUserAdvisorRoles(userId: $input) {
    userId
    roles {
      id
      advisorRole
    }
  }
}
    `;

/**
 * __useGetUserAdvisorRolesQuery__
 *
 * To run a query within a React component, call `useGetUserAdvisorRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAdvisorRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAdvisorRolesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserAdvisorRolesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserAdvisorRolesQuery, GetUserAdvisorRolesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserAdvisorRolesQuery, GetUserAdvisorRolesQueryVariables>(GetUserAdvisorRolesDocument, baseOptions);
      }
export function useGetUserAdvisorRolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserAdvisorRolesQuery, GetUserAdvisorRolesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserAdvisorRolesQuery, GetUserAdvisorRolesQueryVariables>(GetUserAdvisorRolesDocument, baseOptions);
        }
export type GetUserAdvisorRolesQueryHookResult = ReturnType<typeof useGetUserAdvisorRolesQuery>;
export type GetUserAdvisorRolesLazyQueryHookResult = ReturnType<typeof useGetUserAdvisorRolesLazyQuery>;
export type GetUserAdvisorRolesQueryResult = ApolloReactCommon.QueryResult<GetUserAdvisorRolesQuery, GetUserAdvisorRolesQueryVariables>;
export const RemoveAdvisorRoleFromUserDocument = gql`
    mutation removeAdvisorRoleFromUser($input: UserAdvisorRoleInput!) {
  removeAdvisorRoleFromUser(input: $input)
}
    `;
export type RemoveAdvisorRoleFromUserMutationFn = ApolloReactCommon.MutationFunction<RemoveAdvisorRoleFromUserMutation, RemoveAdvisorRoleFromUserMutationVariables>;

/**
 * __useRemoveAdvisorRoleFromUserMutation__
 *
 * To run a mutation, you first call `useRemoveAdvisorRoleFromUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAdvisorRoleFromUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAdvisorRoleFromUserMutation, { data, loading, error }] = useRemoveAdvisorRoleFromUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveAdvisorRoleFromUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveAdvisorRoleFromUserMutation, RemoveAdvisorRoleFromUserMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveAdvisorRoleFromUserMutation, RemoveAdvisorRoleFromUserMutationVariables>(RemoveAdvisorRoleFromUserDocument, baseOptions);
      }
export type RemoveAdvisorRoleFromUserMutationHookResult = ReturnType<typeof useRemoveAdvisorRoleFromUserMutation>;
export type RemoveAdvisorRoleFromUserMutationResult = ApolloReactCommon.MutationResult<RemoveAdvisorRoleFromUserMutation>;
export type RemoveAdvisorRoleFromUserMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveAdvisorRoleFromUserMutation, RemoveAdvisorRoleFromUserMutationVariables>;
export const UpdateAdvisorCvDocument = gql`
    mutation updateAdvisorCV($input: AdvisorCvInput!) {
  updateAdvisorCV(input: $input) {
    id
    firstName
    lastName
    cv
  }
}
    `;
export type UpdateAdvisorCvMutationFn = ApolloReactCommon.MutationFunction<UpdateAdvisorCvMutation, UpdateAdvisorCvMutationVariables>;

/**
 * __useUpdateAdvisorCvMutation__
 *
 * To run a mutation, you first call `useUpdateAdvisorCvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdvisorCvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdvisorCvMutation, { data, loading, error }] = useUpdateAdvisorCvMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdvisorCvMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAdvisorCvMutation, UpdateAdvisorCvMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAdvisorCvMutation, UpdateAdvisorCvMutationVariables>(UpdateAdvisorCvDocument, baseOptions);
      }
export type UpdateAdvisorCvMutationHookResult = ReturnType<typeof useUpdateAdvisorCvMutation>;
export type UpdateAdvisorCvMutationResult = ApolloReactCommon.MutationResult<UpdateAdvisorCvMutation>;
export type UpdateAdvisorCvMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAdvisorCvMutation, UpdateAdvisorCvMutationVariables>;
export const UpdateReferralDocument = gql`
    mutation updateReferral($referralId: ID!, $input: ReferralInput!) {
  updateReferral(referralId: $referralId, input: $input) {
    id
    userId
    firstName
    relationship
    phoneNumber
    email
  }
}
    `;
export type UpdateReferralMutationFn = ApolloReactCommon.MutationFunction<UpdateReferralMutation, UpdateReferralMutationVariables>;

/**
 * __useUpdateReferralMutation__
 *
 * To run a mutation, you first call `useUpdateReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferralMutation, { data, loading, error }] = useUpdateReferralMutation({
 *   variables: {
 *      referralId: // value for 'referralId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReferralMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateReferralMutation, UpdateReferralMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateReferralMutation, UpdateReferralMutationVariables>(UpdateReferralDocument, baseOptions);
      }
export type UpdateReferralMutationHookResult = ReturnType<typeof useUpdateReferralMutation>;
export type UpdateReferralMutationResult = ApolloReactCommon.MutationResult<UpdateReferralMutation>;
export type UpdateReferralMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateReferralMutation, UpdateReferralMutationVariables>;
export const CreateRequestDocument = gql`
    mutation CreateRequest($input: CreateRequestInput!) {
  createRequest(input: $input) {
    id
  }
}
    `;
export type CreateRequestMutationFn = ApolloReactCommon.MutationFunction<CreateRequestMutation, CreateRequestMutationVariables>;

/**
 * __useCreateRequestMutation__
 *
 * To run a mutation, you first call `useCreateRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRequestMutation, { data, loading, error }] = useCreateRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRequestMutation, CreateRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRequestMutation, CreateRequestMutationVariables>(CreateRequestDocument, baseOptions);
      }
export type CreateRequestMutationHookResult = ReturnType<typeof useCreateRequestMutation>;
export type CreateRequestMutationResult = ApolloReactCommon.MutationResult<CreateRequestMutation>;
export type CreateRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRequestMutation, CreateRequestMutationVariables>;
export const ListInitiativesDocument = gql`
    query ListInitiatives($page: Int, $pageSize: Int) {
  initiatives(page: $page, pageSize: $pageSize) {
    results {
      id
      name
    }
  }
}
    `;

/**
 * __useListInitiativesQuery__
 *
 * To run a query within a React component, call `useListInitiativesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInitiativesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInitiativesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useListInitiativesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListInitiativesQuery, ListInitiativesQueryVariables>) {
        return ApolloReactHooks.useQuery<ListInitiativesQuery, ListInitiativesQueryVariables>(ListInitiativesDocument, baseOptions);
      }
export function useListInitiativesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListInitiativesQuery, ListInitiativesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListInitiativesQuery, ListInitiativesQueryVariables>(ListInitiativesDocument, baseOptions);
        }
export type ListInitiativesQueryHookResult = ReturnType<typeof useListInitiativesQuery>;
export type ListInitiativesLazyQueryHookResult = ReturnType<typeof useListInitiativesLazyQuery>;
export type ListInitiativesQueryResult = ApolloReactCommon.QueryResult<ListInitiativesQuery, ListInitiativesQueryVariables>;
export const CreateMemberNoteDocument = gql`
    mutation CreateMemberNote($input: CreateMemberNoteInput!) {
  createMemberNote(input: $input) {
    id
    message
    createdAt
    updatedAt
    user {
      id
      picture
      firstName
      lastName
    }
  }
}
    `;
export type CreateMemberNoteMutationFn = ApolloReactCommon.MutationFunction<CreateMemberNoteMutation, CreateMemberNoteMutationVariables>;

/**
 * __useCreateMemberNoteMutation__
 *
 * To run a mutation, you first call `useCreateMemberNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMemberNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMemberNoteMutation, { data, loading, error }] = useCreateMemberNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMemberNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMemberNoteMutation, CreateMemberNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMemberNoteMutation, CreateMemberNoteMutationVariables>(CreateMemberNoteDocument, baseOptions);
      }
export type CreateMemberNoteMutationHookResult = ReturnType<typeof useCreateMemberNoteMutation>;
export type CreateMemberNoteMutationResult = ApolloReactCommon.MutationResult<CreateMemberNoteMutation>;
export type CreateMemberNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMemberNoteMutation, CreateMemberNoteMutationVariables>;
export const CreateRequestNoteDocument = gql`
    mutation CreateRequestNote($input: CreateRequestNoteInput!) {
  createRequestNote(input: $input) {
    id
    message
    createdAt
    updatedAt
    user {
      id
      picture
      firstName
      lastName
    }
  }
}
    `;
export type CreateRequestNoteMutationFn = ApolloReactCommon.MutationFunction<CreateRequestNoteMutation, CreateRequestNoteMutationVariables>;

/**
 * __useCreateRequestNoteMutation__
 *
 * To run a mutation, you first call `useCreateRequestNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRequestNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRequestNoteMutation, { data, loading, error }] = useCreateRequestNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRequestNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRequestNoteMutation, CreateRequestNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRequestNoteMutation, CreateRequestNoteMutationVariables>(CreateRequestNoteDocument, baseOptions);
      }
export type CreateRequestNoteMutationHookResult = ReturnType<typeof useCreateRequestNoteMutation>;
export type CreateRequestNoteMutationResult = ApolloReactCommon.MutationResult<CreateRequestNoteMutation>;
export type CreateRequestNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRequestNoteMutation, CreateRequestNoteMutationVariables>;
export const ListMemberNotesDocument = gql`
    query ListMemberNotes($memberId: ID!, $page: Int, $pageSize: Int) {
  memberNotes(memberId: $memberId, page: $page, pageSize: $pageSize) {
    total
    results {
      id
      message
      createdAt
      updatedAt
      user {
        id
        picture
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useListMemberNotesQuery__
 *
 * To run a query within a React component, call `useListMemberNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMemberNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMemberNotesQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useListMemberNotesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListMemberNotesQuery, ListMemberNotesQueryVariables>) {
        return ApolloReactHooks.useQuery<ListMemberNotesQuery, ListMemberNotesQueryVariables>(ListMemberNotesDocument, baseOptions);
      }
export function useListMemberNotesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListMemberNotesQuery, ListMemberNotesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListMemberNotesQuery, ListMemberNotesQueryVariables>(ListMemberNotesDocument, baseOptions);
        }
export type ListMemberNotesQueryHookResult = ReturnType<typeof useListMemberNotesQuery>;
export type ListMemberNotesLazyQueryHookResult = ReturnType<typeof useListMemberNotesLazyQuery>;
export type ListMemberNotesQueryResult = ApolloReactCommon.QueryResult<ListMemberNotesQuery, ListMemberNotesQueryVariables>;
export const ListRequestNotesDocument = gql`
    query ListRequestNotes($requestId: ID!, $page: Int, $pageSize: Int) {
  requestNotes(requestId: $requestId, page: $page, pageSize: $pageSize) {
    total
    results {
      id
      message
      createdAt
      updatedAt
      user {
        id
        picture
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useListRequestNotesQuery__
 *
 * To run a query within a React component, call `useListRequestNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRequestNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRequestNotesQuery({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useListRequestNotesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListRequestNotesQuery, ListRequestNotesQueryVariables>) {
        return ApolloReactHooks.useQuery<ListRequestNotesQuery, ListRequestNotesQueryVariables>(ListRequestNotesDocument, baseOptions);
      }
export function useListRequestNotesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListRequestNotesQuery, ListRequestNotesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListRequestNotesQuery, ListRequestNotesQueryVariables>(ListRequestNotesDocument, baseOptions);
        }
export type ListRequestNotesQueryHookResult = ReturnType<typeof useListRequestNotesQuery>;
export type ListRequestNotesLazyQueryHookResult = ReturnType<typeof useListRequestNotesLazyQuery>;
export type ListRequestNotesQueryResult = ApolloReactCommon.QueryResult<ListRequestNotesQuery, ListRequestNotesQueryVariables>;
export const DuplicateRequestDocument = gql`
    mutation DuplicateRequest($input: DuplicateRequestInput!) {
  duplicateRequest(input: $input) {
    id
  }
}
    `;
export type DuplicateRequestMutationFn = ApolloReactCommon.MutationFunction<DuplicateRequestMutation, DuplicateRequestMutationVariables>;

/**
 * __useDuplicateRequestMutation__
 *
 * To run a mutation, you first call `useDuplicateRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateRequestMutation, { data, loading, error }] = useDuplicateRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DuplicateRequestMutation, DuplicateRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<DuplicateRequestMutation, DuplicateRequestMutationVariables>(DuplicateRequestDocument, baseOptions);
      }
export type DuplicateRequestMutationHookResult = ReturnType<typeof useDuplicateRequestMutation>;
export type DuplicateRequestMutationResult = ApolloReactCommon.MutationResult<DuplicateRequestMutation>;
export type DuplicateRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<DuplicateRequestMutation, DuplicateRequestMutationVariables>;
export const RemoveRequestGuestDocument = gql`
    mutation RemoveRequestGuest($input: RemoveRequestGuestPeerInput!) {
  removeRequestGuestPeer(input: $input) {
    id
    guest {
      firstName
      lastName
    }
    peerAddedAt
  }
}
    `;
export type RemoveRequestGuestMutationFn = ApolloReactCommon.MutationFunction<RemoveRequestGuestMutation, RemoveRequestGuestMutationVariables>;

/**
 * __useRemoveRequestGuestMutation__
 *
 * To run a mutation, you first call `useRemoveRequestGuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRequestGuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRequestGuestMutation, { data, loading, error }] = useRemoveRequestGuestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveRequestGuestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveRequestGuestMutation, RemoveRequestGuestMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveRequestGuestMutation, RemoveRequestGuestMutationVariables>(RemoveRequestGuestDocument, baseOptions);
      }
export type RemoveRequestGuestMutationHookResult = ReturnType<typeof useRemoveRequestGuestMutation>;
export type RemoveRequestGuestMutationResult = ApolloReactCommon.MutationResult<RemoveRequestGuestMutation>;
export type RemoveRequestGuestMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveRequestGuestMutation, RemoveRequestGuestMutationVariables>;
export const RemoveRequestPeerDocument = gql`
    mutation RemoveRequestPeer($input: RemoveRequestPeerInput!) {
  removeRequestPeer(input: $input) {
    id
  }
}
    `;
export type RemoveRequestPeerMutationFn = ApolloReactCommon.MutationFunction<RemoveRequestPeerMutation, RemoveRequestPeerMutationVariables>;

/**
 * __useRemoveRequestPeerMutation__
 *
 * To run a mutation, you first call `useRemoveRequestPeerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRequestPeerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRequestPeerMutation, { data, loading, error }] = useRemoveRequestPeerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveRequestPeerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveRequestPeerMutation, RemoveRequestPeerMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveRequestPeerMutation, RemoveRequestPeerMutationVariables>(RemoveRequestPeerDocument, baseOptions);
      }
export type RemoveRequestPeerMutationHookResult = ReturnType<typeof useRemoveRequestPeerMutation>;
export type RemoveRequestPeerMutationResult = ApolloReactCommon.MutationResult<RemoveRequestPeerMutation>;
export type RemoveRequestPeerMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveRequestPeerMutation, RemoveRequestPeerMutationVariables>;
export const ScheduleRequestDocument = gql`
    mutation ScheduleRequest($input: ScheduleRequestInput!) {
  scheduleRequest(input: $input) {
    id
    scheduledAt
  }
}
    `;
export type ScheduleRequestMutationFn = ApolloReactCommon.MutationFunction<ScheduleRequestMutation, ScheduleRequestMutationVariables>;

/**
 * __useScheduleRequestMutation__
 *
 * To run a mutation, you first call `useScheduleRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleRequestMutation, { data, loading, error }] = useScheduleRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScheduleRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ScheduleRequestMutation, ScheduleRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<ScheduleRequestMutation, ScheduleRequestMutationVariables>(ScheduleRequestDocument, baseOptions);
      }
export type ScheduleRequestMutationHookResult = ReturnType<typeof useScheduleRequestMutation>;
export type ScheduleRequestMutationResult = ApolloReactCommon.MutationResult<ScheduleRequestMutation>;
export type ScheduleRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<ScheduleRequestMutation, ScheduleRequestMutationVariables>;
export const AssignRequestDocument = gql`
    mutation AssignRequest($input: AssignRequestInput!) {
  assignRequest(input: $input) {
    id
    assignedTo
    assignee {
      firstName
      lastName
    }
  }
}
    `;
export type AssignRequestMutationFn = ApolloReactCommon.MutationFunction<AssignRequestMutation, AssignRequestMutationVariables>;

/**
 * __useAssignRequestMutation__
 *
 * To run a mutation, you first call `useAssignRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignRequestMutation, { data, loading, error }] = useAssignRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignRequestMutation, AssignRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignRequestMutation, AssignRequestMutationVariables>(AssignRequestDocument, baseOptions);
      }
export type AssignRequestMutationHookResult = ReturnType<typeof useAssignRequestMutation>;
export type AssignRequestMutationResult = ApolloReactCommon.MutationResult<AssignRequestMutation>;
export type AssignRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignRequestMutation, AssignRequestMutationVariables>;
export const ListAdminsSelectionDocument = gql`
    query ListAdminsSelection {
  users(role: ADMIN) {
    id
    firstName
    lastName
  }
}
    `;

/**
 * __useListAdminsSelectionQuery__
 *
 * To run a query within a React component, call `useListAdminsSelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminsSelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminsSelectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAdminsSelectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListAdminsSelectionQuery, ListAdminsSelectionQueryVariables>) {
        return ApolloReactHooks.useQuery<ListAdminsSelectionQuery, ListAdminsSelectionQueryVariables>(ListAdminsSelectionDocument, baseOptions);
      }
export function useListAdminsSelectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListAdminsSelectionQuery, ListAdminsSelectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListAdminsSelectionQuery, ListAdminsSelectionQueryVariables>(ListAdminsSelectionDocument, baseOptions);
        }
export type ListAdminsSelectionQueryHookResult = ReturnType<typeof useListAdminsSelectionQuery>;
export type ListAdminsSelectionLazyQueryHookResult = ReturnType<typeof useListAdminsSelectionLazyQuery>;
export type ListAdminsSelectionQueryResult = ApolloReactCommon.QueryResult<ListAdminsSelectionQuery, ListAdminsSelectionQueryVariables>;
export const ListSuggestedTagsDocument = gql`
    query ListSuggestedTags {
  suggestedTags {
    name
  }
}
    `;

/**
 * __useListSuggestedTagsQuery__
 *
 * To run a query within a React component, call `useListSuggestedTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSuggestedTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSuggestedTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListSuggestedTagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListSuggestedTagsQuery, ListSuggestedTagsQueryVariables>) {
        return ApolloReactHooks.useQuery<ListSuggestedTagsQuery, ListSuggestedTagsQueryVariables>(ListSuggestedTagsDocument, baseOptions);
      }
export function useListSuggestedTagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListSuggestedTagsQuery, ListSuggestedTagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListSuggestedTagsQuery, ListSuggestedTagsQueryVariables>(ListSuggestedTagsDocument, baseOptions);
        }
export type ListSuggestedTagsQueryHookResult = ReturnType<typeof useListSuggestedTagsQuery>;
export type ListSuggestedTagsLazyQueryHookResult = ReturnType<typeof useListSuggestedTagsLazyQuery>;
export type ListSuggestedTagsQueryResult = ApolloReactCommon.QueryResult<ListSuggestedTagsQuery, ListSuggestedTagsQueryVariables>;
export const GetUpdateRequestDocument = gql`
    query GetUpdateRequest($id: ID!) {
  request(id: $id) {
    name
    serviceType
    phase
    category
    description
    tags {
      name
    }
  }
}
    `;

/**
 * __useGetUpdateRequestQuery__
 *
 * To run a query within a React component, call `useGetUpdateRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpdateRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpdateRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUpdateRequestQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUpdateRequestQuery, GetUpdateRequestQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUpdateRequestQuery, GetUpdateRequestQueryVariables>(GetUpdateRequestDocument, baseOptions);
      }
export function useGetUpdateRequestLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUpdateRequestQuery, GetUpdateRequestQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUpdateRequestQuery, GetUpdateRequestQueryVariables>(GetUpdateRequestDocument, baseOptions);
        }
export type GetUpdateRequestQueryHookResult = ReturnType<typeof useGetUpdateRequestQuery>;
export type GetUpdateRequestLazyQueryHookResult = ReturnType<typeof useGetUpdateRequestLazyQuery>;
export type GetUpdateRequestQueryResult = ApolloReactCommon.QueryResult<GetUpdateRequestQuery, GetUpdateRequestQueryVariables>;
export const UpdateRequestDocument = gql`
    mutation UpdateRequest($input: UpdateRequestInput!) {
  updateRequest(input: $input) {
    id
    name
    serviceType
    phase
    category
    description
    tags {
      name
    }
  }
}
    `;
export type UpdateRequestMutationFn = ApolloReactCommon.MutationFunction<UpdateRequestMutation, UpdateRequestMutationVariables>;

/**
 * __useUpdateRequestMutation__
 *
 * To run a mutation, you first call `useUpdateRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRequestMutation, { data, loading, error }] = useUpdateRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRequestMutation, UpdateRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRequestMutation, UpdateRequestMutationVariables>(UpdateRequestDocument, baseOptions);
      }
export type UpdateRequestMutationHookResult = ReturnType<typeof useUpdateRequestMutation>;
export type UpdateRequestMutationResult = ApolloReactCommon.MutationResult<UpdateRequestMutation>;
export type UpdateRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRequestMutation, UpdateRequestMutationVariables>;
export const ArchiveRequestDocument = gql`
    mutation ArchiveRequest($input: ArchiveRequestInput!) {
  archiveRequest(input: $input) {
    id
    archivedAt
  }
}
    `;
export type ArchiveRequestMutationFn = ApolloReactCommon.MutationFunction<ArchiveRequestMutation, ArchiveRequestMutationVariables>;

/**
 * __useArchiveRequestMutation__
 *
 * To run a mutation, you first call `useArchiveRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveRequestMutation, { data, loading, error }] = useArchiveRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ArchiveRequestMutation, ArchiveRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<ArchiveRequestMutation, ArchiveRequestMutationVariables>(ArchiveRequestDocument, baseOptions);
      }
export type ArchiveRequestMutationHookResult = ReturnType<typeof useArchiveRequestMutation>;
export type ArchiveRequestMutationResult = ApolloReactCommon.MutationResult<ArchiveRequestMutation>;
export type ArchiveRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<ArchiveRequestMutation, ArchiveRequestMutationVariables>;
export const GetViewRequestDocument = gql`
    query GetViewRequest($id: ID!) {
  request(id: $id) {
    id
    name
    phase
    description
    serviceType
    category
    assignedTo
    assignee {
      firstName
      lastName
    }
    archivedAt
    scheduledAt
    createdAt
    updatedAt
    status
    tags {
      name
    }
    user {
      id
      firstName
      lastName
      picture
      company {
        title
        name
        address {
          streetAddress
          locality
          region
          postalCode
        }
      }
    }
    peerId
    peer {
      id
      picture
      firstName
      lastName
    }
    peerAddedAt
    guest {
      firstName
      lastName
      phoneNumber
      email
      company {
        name
        title
      }
    }
  }
}
    `;

/**
 * __useGetViewRequestQuery__
 *
 * To run a query within a React component, call `useGetViewRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetViewRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetViewRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetViewRequestQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetViewRequestQuery, GetViewRequestQueryVariables>) {
        return ApolloReactHooks.useQuery<GetViewRequestQuery, GetViewRequestQueryVariables>(GetViewRequestDocument, baseOptions);
      }
export function useGetViewRequestLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetViewRequestQuery, GetViewRequestQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetViewRequestQuery, GetViewRequestQueryVariables>(GetViewRequestDocument, baseOptions);
        }
export type GetViewRequestQueryHookResult = ReturnType<typeof useGetViewRequestQuery>;
export type GetViewRequestLazyQueryHookResult = ReturnType<typeof useGetViewRequestLazyQuery>;
export type GetViewRequestQueryResult = ApolloReactCommon.QueryResult<GetViewRequestQuery, GetViewRequestQueryVariables>;
export const UpdateRequestStatusDocument = gql`
    mutation UpdateRequestStatus($input: UpdateRequestStatusInput!) {
  updateRequestStatus(input: $input) {
    id
    status
  }
}
    `;
export type UpdateRequestStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateRequestStatusMutation, UpdateRequestStatusMutationVariables>;

/**
 * __useUpdateRequestStatusMutation__
 *
 * To run a mutation, you first call `useUpdateRequestStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRequestStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRequestStatusMutation, { data, loading, error }] = useUpdateRequestStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRequestStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRequestStatusMutation, UpdateRequestStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRequestStatusMutation, UpdateRequestStatusMutationVariables>(UpdateRequestStatusDocument, baseOptions);
      }
export type UpdateRequestStatusMutationHookResult = ReturnType<typeof useUpdateRequestStatusMutation>;
export type UpdateRequestStatusMutationResult = ApolloReactCommon.MutationResult<UpdateRequestStatusMutation>;
export type UpdateRequestStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRequestStatusMutation, UpdateRequestStatusMutationVariables>;
export const SelfDocument = gql`
    query Self {
  me {
    id
    firstName
    lastName
    role
    email
    picture
    showOnboarding
  }
}
    `;

/**
 * __useSelfQuery__
 *
 * To run a query within a React component, call `useSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelfQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SelfQuery, SelfQueryVariables>) {
        return ApolloReactHooks.useQuery<SelfQuery, SelfQueryVariables>(SelfDocument, baseOptions);
      }
export function useSelfLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SelfQuery, SelfQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SelfQuery, SelfQueryVariables>(SelfDocument, baseOptions);
        }
export type SelfQueryHookResult = ReturnType<typeof useSelfQuery>;
export type SelfLazyQueryHookResult = ReturnType<typeof useSelfLazyQuery>;
export type SelfQueryResult = ApolloReactCommon.QueryResult<SelfQuery, SelfQueryVariables>;
export const SyncEmailDocument = gql`
    mutation SyncEmail($email: String!) {
  syncEmail(email: $email)
}
    `;
export type SyncEmailMutationFn = ApolloReactCommon.MutationFunction<SyncEmailMutation, SyncEmailMutationVariables>;

/**
 * __useSyncEmailMutation__
 *
 * To run a mutation, you first call `useSyncEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncEmailMutation, { data, loading, error }] = useSyncEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSyncEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SyncEmailMutation, SyncEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<SyncEmailMutation, SyncEmailMutationVariables>(SyncEmailDocument, baseOptions);
      }
export type SyncEmailMutationHookResult = ReturnType<typeof useSyncEmailMutation>;
export type SyncEmailMutationResult = ApolloReactCommon.MutationResult<SyncEmailMutation>;
export type SyncEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SyncEmailMutation, SyncEmailMutationVariables>;
export const UpdateOnboardingDocument = gql`
    mutation UpdateOnboarding($enabled: Boolean!) {
  updateOnboarding(enabled: $enabled) {
    showOnboarding
  }
}
    `;
export type UpdateOnboardingMutationFn = ApolloReactCommon.MutationFunction<UpdateOnboardingMutation, UpdateOnboardingMutationVariables>;

/**
 * __useUpdateOnboardingMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardingMutation, { data, loading, error }] = useUpdateOnboardingMutation({
 *   variables: {
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useUpdateOnboardingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOnboardingMutation, UpdateOnboardingMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOnboardingMutation, UpdateOnboardingMutationVariables>(UpdateOnboardingDocument, baseOptions);
      }
export type UpdateOnboardingMutationHookResult = ReturnType<typeof useUpdateOnboardingMutation>;
export type UpdateOnboardingMutationResult = ApolloReactCommon.MutationResult<UpdateOnboardingMutation>;
export type UpdateOnboardingMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOnboardingMutation, UpdateOnboardingMutationVariables>;
export const CreateEventLogDocument = gql`
    mutation CreateEventLog($input: CreateEventLogInput!) {
  createEventLog(input: $input)
}
    `;
export type CreateEventLogMutationFn = ApolloReactCommon.MutationFunction<CreateEventLogMutation, CreateEventLogMutationVariables>;

/**
 * __useCreateEventLogMutation__
 *
 * To run a mutation, you first call `useCreateEventLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventLogMutation, { data, loading, error }] = useCreateEventLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventLogMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEventLogMutation, CreateEventLogMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEventLogMutation, CreateEventLogMutationVariables>(CreateEventLogDocument, baseOptions);
      }
export type CreateEventLogMutationHookResult = ReturnType<typeof useCreateEventLogMutation>;
export type CreateEventLogMutationResult = ApolloReactCommon.MutationResult<CreateEventLogMutation>;
export type CreateEventLogMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEventLogMutation, CreateEventLogMutationVariables>;
export const ListNewRequestsDocument = gql`
    query ListNewRequests($search: String, $status: Status, $page: Int, $pageSize: Int, $archived: Boolean, $startDate: String, $endDate: String, $filter: [RequestFilter!]) {
  requests(
    search: $search
    status: $status
    page: $page
    pageSize: $pageSize
    archived: $archived
    startDate: $startDate
    endDate: $endDate
    filter: $filter
  ) {
    total
    results {
      id
      name
      phase
      description
      serviceType
      category
      scheduledAt
      createdAt
      updatedAt
      status
      tags {
        name
      }
      user {
        id
        firstName
        lastName
        picture
        company {
          title
          name
          address {
            streetAddress
            locality
            region
            postalCode
          }
        }
      }
    }
  }
}
    `;

/**
 * __useListNewRequestsQuery__
 *
 * To run a query within a React component, call `useListNewRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListNewRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNewRequestsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      status: // value for 'status'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      archived: // value for 'archived'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListNewRequestsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListNewRequestsQuery, ListNewRequestsQueryVariables>) {
        return ApolloReactHooks.useQuery<ListNewRequestsQuery, ListNewRequestsQueryVariables>(ListNewRequestsDocument, baseOptions);
      }
export function useListNewRequestsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListNewRequestsQuery, ListNewRequestsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListNewRequestsQuery, ListNewRequestsQueryVariables>(ListNewRequestsDocument, baseOptions);
        }
export type ListNewRequestsQueryHookResult = ReturnType<typeof useListNewRequestsQuery>;
export type ListNewRequestsLazyQueryHookResult = ReturnType<typeof useListNewRequestsLazyQuery>;
export type ListNewRequestsQueryResult = ApolloReactCommon.QueryResult<ListNewRequestsQuery, ListNewRequestsQueryVariables>;
export const UpdateMemberExpertisesDocument = gql`
    mutation UpdateMemberExpertises($input: UpdateMemberExpertisesInput!) {
  updateMemberExpertises(input: $input)
}
    `;
export type UpdateMemberExpertisesMutationFn = ApolloReactCommon.MutationFunction<UpdateMemberExpertisesMutation, UpdateMemberExpertisesMutationVariables>;

/**
 * __useUpdateMemberExpertisesMutation__
 *
 * To run a mutation, you first call `useUpdateMemberExpertisesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberExpertisesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberExpertisesMutation, { data, loading, error }] = useUpdateMemberExpertisesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberExpertisesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMemberExpertisesMutation, UpdateMemberExpertisesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMemberExpertisesMutation, UpdateMemberExpertisesMutationVariables>(UpdateMemberExpertisesDocument, baseOptions);
      }
export type UpdateMemberExpertisesMutationHookResult = ReturnType<typeof useUpdateMemberExpertisesMutation>;
export type UpdateMemberExpertisesMutationResult = ApolloReactCommon.MutationResult<UpdateMemberExpertisesMutation>;
export type UpdateMemberExpertisesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMemberExpertisesMutation, UpdateMemberExpertisesMutationVariables>;
export const UpdateMemberInitiativesDocument = gql`
    mutation UpdateMemberInitiatives($input: UpdateMemberInitiativesInput!) {
  updateMemberInitiatives(input: $input)
}
    `;
export type UpdateMemberInitiativesMutationFn = ApolloReactCommon.MutationFunction<UpdateMemberInitiativesMutation, UpdateMemberInitiativesMutationVariables>;

/**
 * __useUpdateMemberInitiativesMutation__
 *
 * To run a mutation, you first call `useUpdateMemberInitiativesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberInitiativesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberInitiativesMutation, { data, loading, error }] = useUpdateMemberInitiativesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberInitiativesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMemberInitiativesMutation, UpdateMemberInitiativesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMemberInitiativesMutation, UpdateMemberInitiativesMutationVariables>(UpdateMemberInitiativesDocument, baseOptions);
      }
export type UpdateMemberInitiativesMutationHookResult = ReturnType<typeof useUpdateMemberInitiativesMutation>;
export type UpdateMemberInitiativesMutationResult = ApolloReactCommon.MutationResult<UpdateMemberInitiativesMutation>;
export type UpdateMemberInitiativesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMemberInitiativesMutation, UpdateMemberInitiativesMutationVariables>;
export const UpdateSelfDocument = gql`
    mutation UpdateSelf($input: UpdateMeInput!) {
  updateMe(input: $input) {
    id
  }
}
    `;
export type UpdateSelfMutationFn = ApolloReactCommon.MutationFunction<UpdateSelfMutation, UpdateSelfMutationVariables>;

/**
 * __useUpdateSelfMutation__
 *
 * To run a mutation, you first call `useUpdateSelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSelfMutation, { data, loading, error }] = useUpdateSelfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSelfMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSelfMutation, UpdateSelfMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSelfMutation, UpdateSelfMutationVariables>(UpdateSelfDocument, baseOptions);
      }
export type UpdateSelfMutationHookResult = ReturnType<typeof useUpdateSelfMutation>;
export type UpdateSelfMutationResult = ApolloReactCommon.MutationResult<UpdateSelfMutation>;
export type UpdateSelfMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSelfMutation, UpdateSelfMutationVariables>;
export const AddMemberDocument = gql`
    mutation AddMember($input: RegisterInput!) {
  register(input: $input) {
    id
    firstName
    lastName
    picture
    company {
      name
      title
      address {
        streetAddress
        locality
        region
        postalCode
      }
    }
    updatedAt
    lastLoggedIn
  }
}
    `;
export type AddMemberMutationFn = ApolloReactCommon.MutationFunction<AddMemberMutation, AddMemberMutationVariables>;

/**
 * __useAddMemberMutation__
 *
 * To run a mutation, you first call `useAddMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMemberMutation, { data, loading, error }] = useAddMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMemberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddMemberMutation, AddMemberMutationVariables>) {
        return ApolloReactHooks.useMutation<AddMemberMutation, AddMemberMutationVariables>(AddMemberDocument, baseOptions);
      }
export type AddMemberMutationHookResult = ReturnType<typeof useAddMemberMutation>;
export type AddMemberMutationResult = ApolloReactCommon.MutationResult<AddMemberMutation>;
export type AddMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<AddMemberMutation, AddMemberMutationVariables>;
export const InviteMemberDocument = gql`
    mutation InviteMember($input: InviteMemberInput!) {
  inviteMember(input: $input)
}
    `;
export type InviteMemberMutationFn = ApolloReactCommon.MutationFunction<InviteMemberMutation, InviteMemberMutationVariables>;

/**
 * __useInviteMemberMutation__
 *
 * To run a mutation, you first call `useInviteMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteMemberMutation, { data, loading, error }] = useInviteMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteMemberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteMemberMutation, InviteMemberMutationVariables>) {
        return ApolloReactHooks.useMutation<InviteMemberMutation, InviteMemberMutationVariables>(InviteMemberDocument, baseOptions);
      }
export type InviteMemberMutationHookResult = ReturnType<typeof useInviteMemberMutation>;
export type InviteMemberMutationResult = ApolloReactCommon.MutationResult<InviteMemberMutation>;
export type InviteMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteMemberMutation, InviteMemberMutationVariables>;
export const AnalyticsDocument = gql`
    query Analytics($dateRange: DateRange!) {
  analytics(dateRange: $dateRange) {
    requests
    matches
    members
  }
}
    `;

/**
 * __useAnalyticsQuery__
 *
 * To run a query within a React component, call `useAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsQuery({
 *   variables: {
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function useAnalyticsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AnalyticsQuery, AnalyticsQueryVariables>) {
        return ApolloReactHooks.useQuery<AnalyticsQuery, AnalyticsQueryVariables>(AnalyticsDocument, baseOptions);
      }
export function useAnalyticsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AnalyticsQuery, AnalyticsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AnalyticsQuery, AnalyticsQueryVariables>(AnalyticsDocument, baseOptions);
        }
export type AnalyticsQueryHookResult = ReturnType<typeof useAnalyticsQuery>;
export type AnalyticsLazyQueryHookResult = ReturnType<typeof useAnalyticsLazyQuery>;
export type AnalyticsQueryResult = ApolloReactCommon.QueryResult<AnalyticsQuery, AnalyticsQueryVariables>;
export const ListRecentActivityDocument = gql`
    query ListRecentActivity($page: Int, $pageSize: Int, $dateRange: DateRange) {
  groupedEventLogs(page: $page, pageSize: $pageSize, dateRange: $dateRange) {
    total
    results {
      createdAtDate
      user {
        id
        firstName
        lastName
        picture
      }
      events {
        id
        message
        type
        createdAt
        metadata
      }
    }
  }
}
    `;

/**
 * __useListRecentActivityQuery__
 *
 * To run a query within a React component, call `useListRecentActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRecentActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRecentActivityQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function useListRecentActivityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListRecentActivityQuery, ListRecentActivityQueryVariables>) {
        return ApolloReactHooks.useQuery<ListRecentActivityQuery, ListRecentActivityQueryVariables>(ListRecentActivityDocument, baseOptions);
      }
export function useListRecentActivityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListRecentActivityQuery, ListRecentActivityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListRecentActivityQuery, ListRecentActivityQueryVariables>(ListRecentActivityDocument, baseOptions);
        }
export type ListRecentActivityQueryHookResult = ReturnType<typeof useListRecentActivityQuery>;
export type ListRecentActivityLazyQueryHookResult = ReturnType<typeof useListRecentActivityLazyQuery>;
export type ListRecentActivityQueryResult = ApolloReactCommon.QueryResult<ListRecentActivityQuery, ListRecentActivityQueryVariables>;
export const UpdateMemberDocument = gql`
    mutation UpdateMember($input: UpdateMemberInput!) {
  updateMember(input: $input) {
    id
    firstName
    lastName
    picture
    collectiveExperience
    email
    contactEmail
    phoneNumber
    profileStrength
    socialMedia {
      linkedIn
    }
    company {
      name
      title
      tenure
      address {
        streetAddress
        locality
        region
        postalCode
      }
    }
    createdAt
    updatedAt
    lastLoggedIn
  }
}
    `;
export type UpdateMemberMutationFn = ApolloReactCommon.MutationFunction<UpdateMemberMutation, UpdateMemberMutationVariables>;

/**
 * __useUpdateMemberMutation__
 *
 * To run a mutation, you first call `useUpdateMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberMutation, { data, loading, error }] = useUpdateMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMemberMutation, UpdateMemberMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMemberMutation, UpdateMemberMutationVariables>(UpdateMemberDocument, baseOptions);
      }
export type UpdateMemberMutationHookResult = ReturnType<typeof useUpdateMemberMutation>;
export type UpdateMemberMutationResult = ApolloReactCommon.MutationResult<UpdateMemberMutation>;
export type UpdateMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMemberMutation, UpdateMemberMutationVariables>;
export const CreateAwardcoUserDocument = gql`
    mutation CreateAwardcoUser($input: createAwardcoUserInput!) {
  createAwardcoUser(input: $input) {
    success
    message
  }
}
    `;
export type CreateAwardcoUserMutationFn = ApolloReactCommon.MutationFunction<CreateAwardcoUserMutation, CreateAwardcoUserMutationVariables>;

/**
 * __useCreateAwardcoUserMutation__
 *
 * To run a mutation, you first call `useCreateAwardcoUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAwardcoUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAwardcoUserMutation, { data, loading, error }] = useCreateAwardcoUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAwardcoUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAwardcoUserMutation, CreateAwardcoUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAwardcoUserMutation, CreateAwardcoUserMutationVariables>(CreateAwardcoUserDocument, baseOptions);
      }
export type CreateAwardcoUserMutationHookResult = ReturnType<typeof useCreateAwardcoUserMutation>;
export type CreateAwardcoUserMutationResult = ApolloReactCommon.MutationResult<CreateAwardcoUserMutation>;
export type CreateAwardcoUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAwardcoUserMutation, CreateAwardcoUserMutationVariables>;
export const CreateExpertisesDocument = gql`
    mutation CreateExpertises($input: CreateExpertisesInput!) {
  createExpertises(input: $input)
}
    `;
export type CreateExpertisesMutationFn = ApolloReactCommon.MutationFunction<CreateExpertisesMutation, CreateExpertisesMutationVariables>;

/**
 * __useCreateExpertisesMutation__
 *
 * To run a mutation, you first call `useCreateExpertisesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExpertisesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExpertisesMutation, { data, loading, error }] = useCreateExpertisesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExpertisesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateExpertisesMutation, CreateExpertisesMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateExpertisesMutation, CreateExpertisesMutationVariables>(CreateExpertisesDocument, baseOptions);
      }
export type CreateExpertisesMutationHookResult = ReturnType<typeof useCreateExpertisesMutation>;
export type CreateExpertisesMutationResult = ApolloReactCommon.MutationResult<CreateExpertisesMutation>;
export type CreateExpertisesMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateExpertisesMutation, CreateExpertisesMutationVariables>;
export const CreateInitiativesDocument = gql`
    mutation CreateInitiatives($input: CreateInitiativesInput!) {
  createInitiatives(input: $input)
}
    `;
export type CreateInitiativesMutationFn = ApolloReactCommon.MutationFunction<CreateInitiativesMutation, CreateInitiativesMutationVariables>;

/**
 * __useCreateInitiativesMutation__
 *
 * To run a mutation, you first call `useCreateInitiativesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInitiativesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInitiativesMutation, { data, loading, error }] = useCreateInitiativesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInitiativesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateInitiativesMutation, CreateInitiativesMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateInitiativesMutation, CreateInitiativesMutationVariables>(CreateInitiativesDocument, baseOptions);
      }
export type CreateInitiativesMutationHookResult = ReturnType<typeof useCreateInitiativesMutation>;
export type CreateInitiativesMutationResult = ApolloReactCommon.MutationResult<CreateInitiativesMutation>;
export type CreateInitiativesMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateInitiativesMutation, CreateInitiativesMutationVariables>;
export const DeleteMemberDocument = gql`
    mutation DeleteMember($id: ID!) {
  deleteMember(id: $id)
}
    `;
export type DeleteMemberMutationFn = ApolloReactCommon.MutationFunction<DeleteMemberMutation, DeleteMemberMutationVariables>;

/**
 * __useDeleteMemberMutation__
 *
 * To run a mutation, you first call `useDeleteMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberMutation, { data, loading, error }] = useDeleteMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMemberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMemberMutation, DeleteMemberMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMemberMutation, DeleteMemberMutationVariables>(DeleteMemberDocument, baseOptions);
      }
export type DeleteMemberMutationHookResult = ReturnType<typeof useDeleteMemberMutation>;
export type DeleteMemberMutationResult = ApolloReactCommon.MutationResult<DeleteMemberMutation>;
export type DeleteMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMemberMutation, DeleteMemberMutationVariables>;
export const UserExistsDocument = gql`
    query userExists($employeeId: String!) {
  awardcoUserExists(employeeId: $employeeId) {
    success
    exists
    message
  }
}
    `;

/**
 * __useUserExistsQuery__
 *
 * To run a query within a React component, call `useUserExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserExistsQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useUserExistsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserExistsQuery, UserExistsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserExistsQuery, UserExistsQueryVariables>(UserExistsDocument, baseOptions);
      }
export function useUserExistsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserExistsQuery, UserExistsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserExistsQuery, UserExistsQueryVariables>(UserExistsDocument, baseOptions);
        }
export type UserExistsQueryHookResult = ReturnType<typeof useUserExistsQuery>;
export type UserExistsLazyQueryHookResult = ReturnType<typeof useUserExistsLazyQuery>;
export type UserExistsQueryResult = ApolloReactCommon.QueryResult<UserExistsQuery, UserExistsQueryVariables>;
export const UserBalanceDocument = gql`
    query userBalance($employeeId: String!) {
  awardcoUserBalance(employeeId: $employeeId) {
    success
    message
    balance
    currencyCode
  }
}
    `;

/**
 * __useUserBalanceQuery__
 *
 * To run a query within a React component, call `useUserBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBalanceQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useUserBalanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserBalanceQuery, UserBalanceQueryVariables>) {
        return ApolloReactHooks.useQuery<UserBalanceQuery, UserBalanceQueryVariables>(UserBalanceDocument, baseOptions);
      }
export function useUserBalanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserBalanceQuery, UserBalanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserBalanceQuery, UserBalanceQueryVariables>(UserBalanceDocument, baseOptions);
        }
export type UserBalanceQueryHookResult = ReturnType<typeof useUserBalanceQuery>;
export type UserBalanceLazyQueryHookResult = ReturnType<typeof useUserBalanceLazyQuery>;
export type UserBalanceQueryResult = ApolloReactCommon.QueryResult<UserBalanceQuery, UserBalanceQueryVariables>;
export const GetMemberDocument = gql`
    query GetMember($id: ID!) {
  member(id: $id) {
    id
    firstName
    lastName
    picture
    collectiveExperience
    email
    contactEmail
    phoneNumber
    profileStrength
    expertises {
      id
      name
      type
    }
    initiatives {
      id
      hasPast
      initiative {
        id
        name
      }
    }
    company {
      name
      title
      tenure
      address {
        streetAddress
        locality
        region
        postalCode
      }
    }
    socialMedia {
      linkedIn
    }
    createdAt
    updatedAt
    lastLoggedIn
    referrals {
      id
      lastName
      firstName
      email
      phoneNumber
      relationship
    }
    isAdvisor
    advisorUpdatedDate
    cv
  }
}
    `;

/**
 * __useGetMemberQuery__
 *
 * To run a query within a React component, call `useGetMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMemberQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMemberQuery, GetMemberQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMemberQuery, GetMemberQueryVariables>(GetMemberDocument, baseOptions);
      }
export function useGetMemberLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMemberQuery, GetMemberQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMemberQuery, GetMemberQueryVariables>(GetMemberDocument, baseOptions);
        }
export type GetMemberQueryHookResult = ReturnType<typeof useGetMemberQuery>;
export type GetMemberLazyQueryHookResult = ReturnType<typeof useGetMemberLazyQuery>;
export type GetMemberQueryResult = ApolloReactCommon.QueryResult<GetMemberQuery, GetMemberQueryVariables>;
export const ListMemberRequestsDocument = gql`
    query ListMemberRequests($status: Status, $page: Int, $pageSize: Int, $userId: ID, $peerId: ID, $search: String, $archived: Boolean, $startDate: String, $endDate: String, $filter: [RequestFilter!]) {
  requests(
    status: $status
    page: $page
    pageSize: $pageSize
    userId: $userId
    peerId: $peerId
    search: $search
    archived: $archived
    startDate: $startDate
    endDate: $endDate
    filter: $filter
  ) {
    total
    results {
      id
      name
      serviceType
      phase
      category
      status
      archivedAt
      scheduledAt
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useListMemberRequestsQuery__
 *
 * To run a query within a React component, call `useListMemberRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMemberRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMemberRequestsQuery({
 *   variables: {
 *      status: // value for 'status'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      userId: // value for 'userId'
 *      peerId: // value for 'peerId'
 *      search: // value for 'search'
 *      archived: // value for 'archived'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListMemberRequestsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListMemberRequestsQuery, ListMemberRequestsQueryVariables>) {
        return ApolloReactHooks.useQuery<ListMemberRequestsQuery, ListMemberRequestsQueryVariables>(ListMemberRequestsDocument, baseOptions);
      }
export function useListMemberRequestsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListMemberRequestsQuery, ListMemberRequestsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListMemberRequestsQuery, ListMemberRequestsQueryVariables>(ListMemberRequestsDocument, baseOptions);
        }
export type ListMemberRequestsQueryHookResult = ReturnType<typeof useListMemberRequestsQuery>;
export type ListMemberRequestsLazyQueryHookResult = ReturnType<typeof useListMemberRequestsLazyQuery>;
export type ListMemberRequestsQueryResult = ApolloReactCommon.QueryResult<ListMemberRequestsQuery, ListMemberRequestsQueryVariables>;
export const RequestSuggestionsDocument = gql`
    query RequestSuggestions($search: String!) {
  requestSuggestions(search: $search) {
    general
    tags
  }
}
    `;

/**
 * __useRequestSuggestionsQuery__
 *
 * To run a query within a React component, call `useRequestSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestSuggestionsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useRequestSuggestionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RequestSuggestionsQuery, RequestSuggestionsQueryVariables>) {
        return ApolloReactHooks.useQuery<RequestSuggestionsQuery, RequestSuggestionsQueryVariables>(RequestSuggestionsDocument, baseOptions);
      }
export function useRequestSuggestionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RequestSuggestionsQuery, RequestSuggestionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RequestSuggestionsQuery, RequestSuggestionsQueryVariables>(RequestSuggestionsDocument, baseOptions);
        }
export type RequestSuggestionsQueryHookResult = ReturnType<typeof useRequestSuggestionsQuery>;
export type RequestSuggestionsLazyQueryHookResult = ReturnType<typeof useRequestSuggestionsLazyQuery>;
export type RequestSuggestionsQueryResult = ApolloReactCommon.QueryResult<RequestSuggestionsQuery, RequestSuggestionsQueryVariables>;
export const ListAdminsDocument = gql`
    query ListAdmins($page: Int, $pageSize: Int, $search: String, $orderBy: OrderBy) {
  admins(page: $page, pageSize: $pageSize, search: $search, orderBy: $orderBy) {
    total
    results {
      id
      firstName
      lastName
      picture
      assignedRequestCount
      createdAt
      updatedAt
      lastLoggedIn
    }
  }
}
    `;

/**
 * __useListAdminsQuery__
 *
 * To run a query within a React component, call `useListAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      search: // value for 'search'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useListAdminsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListAdminsQuery, ListAdminsQueryVariables>) {
        return ApolloReactHooks.useQuery<ListAdminsQuery, ListAdminsQueryVariables>(ListAdminsDocument, baseOptions);
      }
export function useListAdminsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListAdminsQuery, ListAdminsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListAdminsQuery, ListAdminsQueryVariables>(ListAdminsDocument, baseOptions);
        }
export type ListAdminsQueryHookResult = ReturnType<typeof useListAdminsQuery>;
export type ListAdminsLazyQueryHookResult = ReturnType<typeof useListAdminsLazyQuery>;
export type ListAdminsQueryResult = ApolloReactCommon.QueryResult<ListAdminsQuery, ListAdminsQueryVariables>;
export const AddAdminDocument = gql`
    mutation AddAdmin($input: AddAdminInput!) {
  addAdmin(input: $input) {
    id
  }
}
    `;
export type AddAdminMutationFn = ApolloReactCommon.MutationFunction<AddAdminMutation, AddAdminMutationVariables>;

/**
 * __useAddAdminMutation__
 *
 * To run a mutation, you first call `useAddAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAdminMutation, { data, loading, error }] = useAddAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAdminMutation, AddAdminMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAdminMutation, AddAdminMutationVariables>(AddAdminDocument, baseOptions);
      }
export type AddAdminMutationHookResult = ReturnType<typeof useAddAdminMutation>;
export type AddAdminMutationResult = ApolloReactCommon.MutationResult<AddAdminMutation>;
export type AddAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAdminMutation, AddAdminMutationVariables>;
export const GetUserAdvisorsDocument = gql`
    query getUserAdvisors($filter: [ID!], $page: Int, $pageSize: Int, $orderBy: OrderBy) {
  getUserAdvisors(
    pageSize: $pageSize
    page: $page
    filter: $filter
    orderBy: $orderBy
  ) {
    total
    results {
      id
      role
      firstName
      lastName
      advisorUpdatedDate
      picture
      cv
      referrals {
        firstName
        lastName
        email
        phoneNumber
        relationship
      }
    }
  }
}
    `;

/**
 * __useGetUserAdvisorsQuery__
 *
 * To run a query within a React component, call `useGetUserAdvisorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAdvisorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAdvisorsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetUserAdvisorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserAdvisorsQuery, GetUserAdvisorsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserAdvisorsQuery, GetUserAdvisorsQueryVariables>(GetUserAdvisorsDocument, baseOptions);
      }
export function useGetUserAdvisorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserAdvisorsQuery, GetUserAdvisorsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserAdvisorsQuery, GetUserAdvisorsQueryVariables>(GetUserAdvisorsDocument, baseOptions);
        }
export type GetUserAdvisorsQueryHookResult = ReturnType<typeof useGetUserAdvisorsQuery>;
export type GetUserAdvisorsLazyQueryHookResult = ReturnType<typeof useGetUserAdvisorsLazyQuery>;
export type GetUserAdvisorsQueryResult = ApolloReactCommon.QueryResult<GetUserAdvisorsQuery, GetUserAdvisorsQueryVariables>;
export const ListMembersDocument = gql`
    query ListMembers($page: Int, $pageSize: Int, $search: String, $orderBy: OrderBy, $filter: [UserFilter!], $initiatives: [String!], $expertises: [String!]) {
  members(
    page: $page
    pageSize: $pageSize
    search: $search
    orderBy: $orderBy
    filter: $filter
    initiatives: $initiatives
    expertises: $expertises
  ) {
    total
    results {
      id
      firstName
      lastName
      picture
      company {
        name
        title
        address {
          streetAddress
          locality
          region
          postalCode
        }
      }
      totalRequestCount: requestCount
      receivedRequestCount: requestCount(status: RECEIVED)
      activeRequestCount: requestCount(status: ACTIVE)
      scheduledRequestCount: requestCount(status: SCHEDULED)
      domainExpertises: userExpertises(type: DOMAIN, pageSize: 3) {
        total
        results {
          createdAt
          expertise {
            name
          }
        }
      }
      subjectMatterExpertises: userExpertises(type: SUBJECT_MATTER, pageSize: 3) {
        total
        results {
          createdAt
          expertise {
            name
          }
        }
      }
      technologyProviderExpertises: userExpertises(
        type: TECHNOLOGY_PROVIDER
        pageSize: 3
      ) {
        total
        results {
          createdAt
          expertise {
            name
          }
        }
      }
      createdAt
      updatedAt
      lastLoggedIn
    }
  }
}
    `;

/**
 * __useListMembersQuery__
 *
 * To run a query within a React component, call `useListMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMembersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      search: // value for 'search'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *      initiatives: // value for 'initiatives'
 *      expertises: // value for 'expertises'
 *   },
 * });
 */
export function useListMembersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListMembersQuery, ListMembersQueryVariables>) {
        return ApolloReactHooks.useQuery<ListMembersQuery, ListMembersQueryVariables>(ListMembersDocument, baseOptions);
      }
export function useListMembersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListMembersQuery, ListMembersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListMembersQuery, ListMembersQueryVariables>(ListMembersDocument, baseOptions);
        }
export type ListMembersQueryHookResult = ReturnType<typeof useListMembersQuery>;
export type ListMembersLazyQueryHookResult = ReturnType<typeof useListMembersLazyQuery>;
export type ListMembersQueryResult = ApolloReactCommon.QueryResult<ListMembersQuery, ListMembersQueryVariables>;
export const MemberSuggestionsDocument = gql`
    query MemberSuggestions($search: String!) {
  memberSuggestions(search: $search) {
    names
    initiatives {
      id
      name
    }
    expertises {
      id
      name
      type
    }
  }
}
    `;

/**
 * __useMemberSuggestionsQuery__
 *
 * To run a query within a React component, call `useMemberSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberSuggestionsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useMemberSuggestionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MemberSuggestionsQuery, MemberSuggestionsQueryVariables>) {
        return ApolloReactHooks.useQuery<MemberSuggestionsQuery, MemberSuggestionsQueryVariables>(MemberSuggestionsDocument, baseOptions);
      }
export function useMemberSuggestionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MemberSuggestionsQuery, MemberSuggestionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MemberSuggestionsQuery, MemberSuggestionsQueryVariables>(MemberSuggestionsDocument, baseOptions);
        }
export type MemberSuggestionsQueryHookResult = ReturnType<typeof useMemberSuggestionsQuery>;
export type MemberSuggestionsLazyQueryHookResult = ReturnType<typeof useMemberSuggestionsLazyQuery>;
export type MemberSuggestionsQueryResult = ApolloReactCommon.QueryResult<MemberSuggestionsQuery, MemberSuggestionsQueryVariables>;
export const GetMemberSelfDocument = gql`
    query GetMemberSelf {
  me {
    id
    firstName
    lastName
    picture
    collectiveExperience
    email
    contactEmail
    phoneNumber
    profileStrength
    expertises {
      id
      name
      type
    }
    initiatives {
      id
      hasPast
      initiative {
        id
        name
      }
    }
    company {
      name
      title
      tenure
      address {
        streetAddress
        locality
        region
        postalCode
      }
    }
    socialMedia {
      linkedIn
    }
    createdAt
    updatedAt
    lastLoggedIn
    isAdvisor
    advisorUpdatedDate
    referrals {
      id
      lastName
      firstName
      email
      phoneNumber
      relationship
    }
    cv
  }
}
    `;

/**
 * __useGetMemberSelfQuery__
 *
 * To run a query within a React component, call `useGetMemberSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMemberSelfQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMemberSelfQuery, GetMemberSelfQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMemberSelfQuery, GetMemberSelfQueryVariables>(GetMemberSelfDocument, baseOptions);
      }
export function useGetMemberSelfLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMemberSelfQuery, GetMemberSelfQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMemberSelfQuery, GetMemberSelfQueryVariables>(GetMemberSelfDocument, baseOptions);
        }
export type GetMemberSelfQueryHookResult = ReturnType<typeof useGetMemberSelfQuery>;
export type GetMemberSelfLazyQueryHookResult = ReturnType<typeof useGetMemberSelfLazyQuery>;
export type GetMemberSelfQueryResult = ApolloReactCommon.QueryResult<GetMemberSelfQuery, GetMemberSelfQueryVariables>;
export const UpdateMemberAdvisorStatusDocument = gql`
    mutation updateMemberAdvisorStatus($input: UpdateMemberAdvisorStatusInput!) {
  updateMemberAdvisorStatus(input: $input) {
    isAdvisor
    advisorUpdatedDate
  }
}
    `;
export type UpdateMemberAdvisorStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateMemberAdvisorStatusMutation, UpdateMemberAdvisorStatusMutationVariables>;

/**
 * __useUpdateMemberAdvisorStatusMutation__
 *
 * To run a mutation, you first call `useUpdateMemberAdvisorStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberAdvisorStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberAdvisorStatusMutation, { data, loading, error }] = useUpdateMemberAdvisorStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberAdvisorStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMemberAdvisorStatusMutation, UpdateMemberAdvisorStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMemberAdvisorStatusMutation, UpdateMemberAdvisorStatusMutationVariables>(UpdateMemberAdvisorStatusDocument, baseOptions);
      }
export type UpdateMemberAdvisorStatusMutationHookResult = ReturnType<typeof useUpdateMemberAdvisorStatusMutation>;
export type UpdateMemberAdvisorStatusMutationResult = ApolloReactCommon.MutationResult<UpdateMemberAdvisorStatusMutation>;
export type UpdateMemberAdvisorStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMemberAdvisorStatusMutation, UpdateMemberAdvisorStatusMutationVariables>;
export const UpdateMemberPhotoDocument = gql`
    mutation UpdateMemberPhoto($input: UpdateMemberPhotoInput!) {
  updateMemberPhoto(input: $input)
}
    `;
export type UpdateMemberPhotoMutationFn = ApolloReactCommon.MutationFunction<UpdateMemberPhotoMutation, UpdateMemberPhotoMutationVariables>;

/**
 * __useUpdateMemberPhotoMutation__
 *
 * To run a mutation, you first call `useUpdateMemberPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberPhotoMutation, { data, loading, error }] = useUpdateMemberPhotoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberPhotoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMemberPhotoMutation, UpdateMemberPhotoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMemberPhotoMutation, UpdateMemberPhotoMutationVariables>(UpdateMemberPhotoDocument, baseOptions);
      }
export type UpdateMemberPhotoMutationHookResult = ReturnType<typeof useUpdateMemberPhotoMutation>;
export type UpdateMemberPhotoMutationResult = ApolloReactCommon.MutationResult<UpdateMemberPhotoMutation>;
export type UpdateMemberPhotoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMemberPhotoMutation, UpdateMemberPhotoMutationVariables>;
export const CompleteOnboardingDocument = gql`
    mutation CompleteOnboarding($input: CompleteOnboardingInput!) {
  completeOnboarding(input: $input)
}
    `;
export type CompleteOnboardingMutationFn = ApolloReactCommon.MutationFunction<CompleteOnboardingMutation, CompleteOnboardingMutationVariables>;

/**
 * __useCompleteOnboardingMutation__
 *
 * To run a mutation, you first call `useCompleteOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeOnboardingMutation, { data, loading, error }] = useCompleteOnboardingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteOnboardingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompleteOnboardingMutation, CompleteOnboardingMutationVariables>) {
        return ApolloReactHooks.useMutation<CompleteOnboardingMutation, CompleteOnboardingMutationVariables>(CompleteOnboardingDocument, baseOptions);
      }
export type CompleteOnboardingMutationHookResult = ReturnType<typeof useCompleteOnboardingMutation>;
export type CompleteOnboardingMutationResult = ApolloReactCommon.MutationResult<CompleteOnboardingMutation>;
export type CompleteOnboardingMutationOptions = ApolloReactCommon.BaseMutationOptions<CompleteOnboardingMutation, CompleteOnboardingMutationVariables>;
export const ListExpertisesDocument = gql`
    query ListExpertises($page: Int, $pageSize: Int, $type: ExpertiseType) {
  expertises(page: $page, pageSize: $pageSize, type: $type) {
    results {
      id
      name
      type
    }
  }
}
    `;

/**
 * __useListExpertisesQuery__
 *
 * To run a query within a React component, call `useListExpertisesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListExpertisesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListExpertisesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useListExpertisesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListExpertisesQuery, ListExpertisesQueryVariables>) {
        return ApolloReactHooks.useQuery<ListExpertisesQuery, ListExpertisesQueryVariables>(ListExpertisesDocument, baseOptions);
      }
export function useListExpertisesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListExpertisesQuery, ListExpertisesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListExpertisesQuery, ListExpertisesQueryVariables>(ListExpertisesDocument, baseOptions);
        }
export type ListExpertisesQueryHookResult = ReturnType<typeof useListExpertisesQuery>;
export type ListExpertisesLazyQueryHookResult = ReturnType<typeof useListExpertisesLazyQuery>;
export type ListExpertisesQueryResult = ApolloReactCommon.QueryResult<ListExpertisesQuery, ListExpertisesQueryVariables>;
export const AddRequestPeerDocument = gql`
    mutation AddRequestPeer($input: AddRequestPeerInput!) {
  addRequestPeer(input: $input) {
    id
  }
}
    `;
export type AddRequestPeerMutationFn = ApolloReactCommon.MutationFunction<AddRequestPeerMutation, AddRequestPeerMutationVariables>;

/**
 * __useAddRequestPeerMutation__
 *
 * To run a mutation, you first call `useAddRequestPeerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRequestPeerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRequestPeerMutation, { data, loading, error }] = useAddRequestPeerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRequestPeerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddRequestPeerMutation, AddRequestPeerMutationVariables>) {
        return ApolloReactHooks.useMutation<AddRequestPeerMutation, AddRequestPeerMutationVariables>(AddRequestPeerDocument, baseOptions);
      }
export type AddRequestPeerMutationHookResult = ReturnType<typeof useAddRequestPeerMutation>;
export type AddRequestPeerMutationResult = ApolloReactCommon.MutationResult<AddRequestPeerMutation>;
export type AddRequestPeerMutationOptions = ApolloReactCommon.BaseMutationOptions<AddRequestPeerMutation, AddRequestPeerMutationVariables>;
export const CheckEmailDocument = gql`
    mutation CheckEmail($email: String!) {
  checkEmail(email: $email)
}
    `;
export type CheckEmailMutationFn = ApolloReactCommon.MutationFunction<CheckEmailMutation, CheckEmailMutationVariables>;

/**
 * __useCheckEmailMutation__
 *
 * To run a mutation, you first call `useCheckEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkEmailMutation, { data, loading, error }] = useCheckEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CheckEmailMutation, CheckEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<CheckEmailMutation, CheckEmailMutationVariables>(CheckEmailDocument, baseOptions);
      }
export type CheckEmailMutationHookResult = ReturnType<typeof useCheckEmailMutation>;
export type CheckEmailMutationResult = ApolloReactCommon.MutationResult<CheckEmailMutation>;
export type CheckEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<CheckEmailMutation, CheckEmailMutationVariables>;
export const CheckPhoneNumberDocument = gql`
    mutation CheckPhoneNumber($phoneNumber: String!) {
  checkPhoneNumber(phoneNumber: $phoneNumber)
}
    `;
export type CheckPhoneNumberMutationFn = ApolloReactCommon.MutationFunction<CheckPhoneNumberMutation, CheckPhoneNumberMutationVariables>;

/**
 * __useCheckPhoneNumberMutation__
 *
 * To run a mutation, you first call `useCheckPhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckPhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkPhoneNumberMutation, { data, loading, error }] = useCheckPhoneNumberMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useCheckPhoneNumberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CheckPhoneNumberMutation, CheckPhoneNumberMutationVariables>) {
        return ApolloReactHooks.useMutation<CheckPhoneNumberMutation, CheckPhoneNumberMutationVariables>(CheckPhoneNumberDocument, baseOptions);
      }
export type CheckPhoneNumberMutationHookResult = ReturnType<typeof useCheckPhoneNumberMutation>;
export type CheckPhoneNumberMutationResult = ApolloReactCommon.MutationResult<CheckPhoneNumberMutation>;
export type CheckPhoneNumberMutationOptions = ApolloReactCommon.BaseMutationOptions<CheckPhoneNumberMutation, CheckPhoneNumberMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($input: RegisterInput!) {
  register(input: $input) {
    email
  }
}
    `;
export type SignUpMutationFn = ApolloReactCommon.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        return ApolloReactHooks.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, baseOptions);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = ApolloReactCommon.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = ApolloReactCommon.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;