import React from 'react';

const folder = {
  path: (
    <svg fill="none">
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      >
        <path d="m16.2433 16.3511 2.25-6c.2452-.65373-.2381-1.3511-.9363-1.3511h-8.96726-2.89674c-.41684 0-.78997.25857-.93633.64888l-.75667 2.01782-1.49333 3.9822c-.24516.6537.23812 1.3511.93633 1.3511h11.864c.4168 0 .79-.2586.9363-.6489z" />
        <path d="m4.5 17h-1.5c-.55228 0-1-.4477-1-1v-7-3-.5c0-.55229.44771-1 1-1h3.58974c.55229 0 1 .44772 1 1 0 .55228.44772 1 1 1h6.41026c.5523 0 1 .44771 1 1v1.5" />
      </g>
    </svg>
  ),
  viewBox: '0 0 20 20',
};

export default folder;
