import React from 'react';

const filter = {
  path: (
    <svg fill="none">
      <path
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M4 5H1M19 5H8M12 10H1M19 10h-3M6 15H1M19 15h-9"
      />
      <circle cx="6" cy="5" r="2" stroke="currentColor" strokeWidth="1.5" />
      <circle cx="14" cy="10" r="2" stroke="currentColor" strokeWidth="1.5" />
      <circle cx="8" cy="15" r="2" stroke="currentColor" strokeWidth="1.5" />
    </svg>
  ),
  viewBox: '0 0 20 20',
};

export default filter;
