import React from 'react';

const eye = {
  path: (
    <>
      <path
        d="M9.99998 14.2797C6.75997 14.2797 3.63998 12.9597 1.23998 10.6797C0.999978 10.4397 1 10.1997 1 9.95966C1 9.71966 1.05489 9.41549 1.23998 9.23966C3.63998 6.95965 6.63997 5.63965 9.99998 5.63965C13.36 5.63965 16.3601 6.95965 18.7601 9.23966C19.0001 9.47966 19 9.71966 19 9.95966C19 10.1997 18.9214 10.5264 18.7601 10.6797C16.3601 12.9597 13.24 14.2797 9.99998 14.2797Z"
        stroke="#495057"
        stroke-width="1.5"
        fill="transparent"
      />
      <circle
        cx="10.0008"
        cy="9.83966"
        r="4.20001"
        stroke="#495057"
        stroke-width="1.5"
        fill="transparent"
      />
    </>
  ),
  viewBox: '0 0 20 20'
};

export default eye;
