import React from 'react';

const carat = {
  path: (
    <path
      d="M5.068 1.451a1 1 0 0 1 1.72 0l4.173 7.04a1 1 0 0 1-.86 1.509H1.755a1 1 0 0 1-.86-1.51l4.173-7.039z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 12 10',
};

export default carat;
