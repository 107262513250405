import React from 'react';

const email = {
  path: (
    <svg fill="none">
      <rect
        width="15"
        height="9.844"
        x="2.5"
        y="5.315"
        stroke="currentColor"
        strokeWidth="1.5"
        rx=".8"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.5"
        d="M2.819 5.75L10 11.78l7.18-6.031"
      />
    </svg>
  ),
  viewBox: '0 0 20 20',
};

export default email;
