import { useToast } from '@chakra-ui/core';
import * as firebase from 'firebase/app';
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from '../components/Loader';
import {
  EventLogType,
  SelfQuery,
  useCreateEventLogMutation,
  useSelfLazyQuery,
  useSyncEmailMutation,
  useUpdateOnboardingMutation,
  useUpdateSelfMutation,
} from '../generated/graphql';
import { toastError, toastSuccess } from '../utils';
import { Auth0Client } from '@auth0/auth0-spa-js';
import config from '../config';

const auth0 = new Auth0Client({
  domain: config.auth0.domain,
  client_id: config.auth0.clientID,
});

export type AuthStatus =
  | 'checking'
  | 'loading'
  | 'authenticated'
  | 'unauthenticated'
  | 'error';

export type IUser = SelfQuery['me'];

export interface IAuthContext {
  user: IUser | null;
  firebaseUser: firebase.User | null;
  status: AuthStatus;
  authenticated: boolean;
  emailVerified?: boolean;
  login: (email: string, password: string) => void;
  logout: () => void;
  sendEmailVerification: (hideSuccessToast?: boolean) => void;
  verifyEmail: (oobCode: string) => Promise<boolean>;
  updateProfile: (user: Partial<IUser>) => Promise<void>;
  updateProfilePicture: (url: string) => Promise<void>;
  updateOnboarding: (enabled: boolean) => Promise<void>;
  verifyBeforeUpdateEmail: (email: string) => void;
  applyVerifyBeforeUpdateEmail: (actionCode: string) => Promise<void>;
  recoverEmail: (actionCode: string) => void;
  refetch: (variables?: any) => Promise<any>;
}

const AuthContext = React.createContext<IAuthContext | null>(null);

export const getToken = async () => {
  const token = await auth0.getTokenSilently();
  const claims = await auth0.getIdTokenClaims();
  return claims.__raw;
};

interface Props {
  loading?: any;
  children: any;
}

function AuthProvider({ loading = <Loader />, children, ...props }: Props) {
  const [status, setStatus] = useState<AuthStatus>('unauthenticated');
  const [user, setUser] = useState<IUser | null>(null);
  const [emailVerified, setEmailVerified] = useState(false); // Need this to refresh app on verification
  const toast = useToast();
  const [fetchSelf, { error, refetch }] = useSelfLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [updateSelf] = useUpdateSelfMutation();
  const [updateOnboardingMutation] = useUpdateOnboardingMutation();
  const [syncEmail] = useSyncEmailMutation();
  const [createLoginEventLog] = useCreateEventLogMutation({
    variables: { input: { type: EventLogType.Login } },
  });

  const { isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (isLoading) {
      setStatus('loading');
    } else if (isAuthenticated) {
      setStatus('authenticated');
      setUser(user);
    }
  }, [isAuthenticated, isLoading, user]);

  useEffect(() => {
    if (error) {
      toastError(toast, error);
      setStatus('error');
    }
  }, [error, toast]);

  const login = async (email: string, password: string) => {
    try {
      setStatus('loading');
      await firebase.auth().signInWithEmailAndPassword(email, password);
      await createLoginEventLog();
    } catch (e) {
      setStatus('error');
      toastError(toast, e);
    }
  };

  const logout = async () => {
    try {
      setStatus('loading');
      setUser(null);
      await firebase.auth().signOut();
    } catch (e) {
      setStatus('error');
      toastError(toast, e);
    }
  };

  const sendEmailVerification = async (hideSuccessToast = false) => {
    try {
      const { currentUser } = firebase.auth();
      await currentUser?.sendEmailVerification();
      if (!hideSuccessToast) {
        toast({
          title: 'Email sent',
          description: `Check your inbox or spam under ${currentUser?.email}`,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (e) {
      toast({
        title: 'Error',
        description: e.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const verifyEmail = async (oobCode: string) => {
    try {
      await firebase.auth().applyActionCode(oobCode || '');
      toast({
        title: 'Success!',
        description: 'Email is successfully verified',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      setEmailVerified(true);
      return true;
    } catch (e) {
      toast({
        title: 'An error occurred',
        description: e.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return false;
    }
  };

  const verifyBeforeUpdateEmail = async (email: string) => {
    await firebase.auth().currentUser?.verifyBeforeUpdateEmail(email);
    toast({
      description: `Your new email needs to be verified before it can be updated on your account. \nCheck your inbox/spam under ${email} for a verification email to complete the update.`,
      status: 'info',
      isClosable: true,
      duration: 9000,
    });
  };

  const applyVerifyBeforeUpdateEmail = async (actionCode: string) => {
    try {
      const auth = firebase.auth();
      const info = await auth.checkActionCode(actionCode);
      const { email } = info.data;
      if (info.operation === 'VERIFY_AND_CHANGE_EMAIL' && email) {
        await auth.applyActionCode(actionCode);
        await syncEmail({ variables: { email } });
        try {
          await auth.currentUser?.reload();
        } catch (error) {}
        toastSuccess(
          toast,
          `Email successfully updated to ${email}. \nPlease login again with your updated email to continue using the app.`,
        );
      }
    } catch (error) {
      toastError(toast, error);
    }
  };

  const recoverEmail = async (actionCode: string) => {
    const auth = firebase.auth();
    const info = await auth.checkActionCode(actionCode);
    const { previousEmail } = info.data;

    if (info.operation === 'RECOVER_EMAIL' && previousEmail) {
      try {
        await auth.applyActionCode(actionCode);
        toastSuccess(toast, `Email successfully restored to ${previousEmail}`);

        // Sync restored previous email with backend
        syncEmail({ variables: { email: previousEmail } });
      } catch (error) {
        toastError(toast, error);
      }
    }
  };

  const updateProfile = async (user: Partial<IUser>) => {
    try {
      await updateSelf({
        variables: {
          input: {
            firstName: user!.firstName!,
            lastName: user!.lastName!,
            email: user!.email!,
          },
        },
      });
      await refetch();
      toastSuccess(toast, 'Updated profile');
    } catch (e) {
      toastError(toast, e);
    }
  };

  const updateProfilePicture = async (photoURL: string) => {
    try {
      await firebase.auth().currentUser!.updateProfile({ photoURL });
      await refetch();
      toastSuccess(toast, 'Updated profile picture');
    } catch (e) {
      toastError(toast, e);
    }
  };

  const updateOnboarding = async (enabled: boolean) => {
    await updateOnboardingMutation({ variables: { enabled } });
    await refetch();
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        status,
        authenticated: status === 'authenticated',
        login,
        logout,
        emailVerified,
        sendEmailVerification,
        verifyEmail,
        firebaseUser: firebase.auth().currentUser,
        updateProfile,
        updateProfilePicture,
        updateOnboarding,
        verifyBeforeUpdateEmail,
        applyVerifyBeforeUpdateEmail,
        recoverEmail,
        refetch,
      }}
      {...props}
    >
      {status === 'checking' ? loading : children}
    </AuthContext.Provider>
  );
}

const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error('AuthContext missing');
  }

  return context;
};

export { AuthProvider, useAuth };
