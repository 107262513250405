const config = {
  graphqlURL: process.env.REACT_APP_PROXY_URL
    ? '/api/graphql'
    : process.env.REACT_APP_GRAPHQL_URL,

  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  },

  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  },

  cloudinary: {
    cvFolder: process.env.REACT_APP_CLOUDINARY_CV_PRESET,
    avatarFolder:process.env.REACT_APP_CLOUDINARY_AVATAR_PRESET,
    cloudName: process.env.REACT_APP_CLOUDINARY_CLOUDNAME
  }
};

export default config;
