import React from 'react';
import { Flex, FlexProps, Image } from '@chakra-ui/core';
import Logo from '../../assets/images/logo.svg';
import { NavLink } from 'react-router-dom';

// Used if you want to position fixed and to need to offset
export const HEADER_HEIGHT = [54, 72];

export interface IHeaderProps extends FlexProps {
  fixed?: boolean;
}

export default function Header({ fixed, ...props }: IHeaderProps) {
  const fixedProps = fixed
    ? ({
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        zIndex: 10,
      } as FlexProps)
    : {};
  return (
    <Flex
      as="header"
      minHeight={HEADER_HEIGHT}
      bg="dark"
      align="center"
      justify="center"
      px={[6, 6, 50]}
      {...fixedProps}
      {...props}
    >
      <NavLink to="/">
        <Image w="10rem" src={Logo} alt="captive eight logo" />
      </NavLink>
      <Flex as="nav" flexGrow={1} align="center" justify="flex-end">
        {props.children}
      </Flex>
    </Flex>
  );
}
