import { Flex, FlexProps, Spinner, SpinnerProps } from '@chakra-ui/core';
import React from 'react';

export interface LoaderProps extends FlexProps {
  size?: SpinnerProps['size'];
}

export default function Loader({ size = 'xl', ...props }: LoaderProps) {
  return (
    <Flex h="100%" align="center" justify="center" {...props}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="peach"
        color="orange.400"
        size={size}
      />
    </Flex>
  );
}
