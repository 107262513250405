import React from 'react';

const pencil = {
  path: (
    <svg fill="none">
      <path
        d="M18.7084 5.17536L14.7122 1.17914L12.048 3.84329L16.0442 7.83951L18.7084 5.17536Z"
        fill="currentColor"
      />
      <path
        d="M10.4006 6.82233L2.28653 14.9364L1.90576 18.1095L4.95193 17.6018L13.066 9.48772L10.4006 6.82233Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  ),
  viewBox: '0 0 21 20',
};

export default pencil;
