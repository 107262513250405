import React from 'react';

const phone = {
  path: (
    <svg fill="none">
      <path
        fill="currentColor"
        d="M3.237 6.929a13.41 13.41 0 005.834 5.834l1.948-1.948a.88.88 0 01.903-.212 10.1 10.1 0 003.16.504c.487 0 .885.399.885.885v3.09a.888.888 0 01-.885.885C6.769 15.967.032 9.23.032.918c0-.487.4-.885.886-.885h3.098c.487 0 .886.398.886.885 0 1.107.177 2.169.504 3.16a.888.888 0 01-.221.903L3.237 6.93z"
      />
    </svg>
  ),
  viewBox: '0 0 16 16',
};

export default phone;
