import ApolloClient from 'apollo-boost';
import { ErrorResponse } from 'apollo-link-error';
import config from '../config';
import { Auth0Client } from '@auth0/auth0-spa-js';

const auth0 = new Auth0Client({
  domain: config.auth0.domain,
  client_id: config.auth0.clientID,
});

export default new ApolloClient({
  uri: config.graphqlURL,
  request: async operation => {
    try {
      // const token = await auth0.getTokenSilently()
      // const claims = await auth0.getIdTokenClaims()
      // const idToken = claims.__raw

      // if (token) {
      //   operation.setContext({
      //     headers: { authorization: idToken},
      //   });
      // }
      if (localStorage.getItem('auth0')) {
        operation.setContext({
          headers: { authorization: localStorage.getItem('auth0') },
        });
      }
    } catch (error) {
      console.log(error);
    }
  },
  onError: ({ graphQLErrors, networkError }: ErrorResponse) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(
          `GraphQL error in ApolloClient: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      });
    }
    if (networkError) {
      console.log('Network error in ApolloClient: ', networkError);
    }
  },
});
