import React from 'react';

const person = {
  path: (
    <svg fill="none">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M24.295 9.478a5.516 5.516 0 00-5.514 5.52 5.52 5.52 0 005.522 5.516 5.516 5.516 0 005.515-5.52 5.52 5.52 0 00-5.523-5.516zM15.293 15a9.002 9.002 0 019-9.01 9.01 9.01 0 019.013 9.003 9.002 9.002 0 01-9 9.01A9.01 9.01 0 0115.293 15zM6.458 41.367l3.72-.002c2.257-5.415 7.605-9.223 13.845-9.225 6.24-.002 11.594 3.801 13.86 9.215l3.72-.002c-2.43-7.376-9.386-12.7-17.582-12.697-8.197.003-15.144 5.333-17.563 12.71z"
        clipRule="evenodd"
      />
    </svg>
  ),
  viewBox: '0 0 48 48',
};

export default person;
