import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { Auth0Provider } from '@auth0/auth0-react';
import { CSSReset } from '@chakra-ui/core';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import config from './config';
import Providers from './context';
import './index.css';
import * as serviceWorker from './serviceWorker';

firebase.initializeApp(config.firebase);
if (process.env.REACT_APP_FIREBASE_MEASUREMENT_ID) {
  firebase.analytics();
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={config.auth0.domain!}
      clientId={config.auth0.clientID!}
      redirectUri={window.location.origin}
    >
      <Providers>
        <CSSReset />
        <App />
      </Providers>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
