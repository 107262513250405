import React from 'react';

const linkedIn = {
  path: (
    <svg fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0719 19.9379V15.0189C21.0719 12.3834 19.6649 11.157 17.7892 11.157C16.2755 11.157 15.5972 11.9899 15.2192 12.5741V11.3586H12.3674C12.4052 12.1637 12.3674 19.9379 12.3674 19.9379H15.2192V15.1466C15.2192 14.8908 15.2377 14.6346 15.3133 14.4515C15.5191 13.9391 15.9883 13.4086 16.7758 13.4086C17.8081 13.4086 18.2206 14.1953 18.2206 15.3482V19.9384H21.0719V19.9379ZM9.36317 10.1876C10.3573 10.1876 10.9768 9.52823 10.9768 8.70461C10.9583 7.86335 10.3573 7.22285 9.38207 7.22285C8.40683 7.22285 7.76885 7.86293 7.76885 8.70461C7.76885 9.52823 8.38793 10.1876 9.34511 10.1876H9.36317ZM14.0004 26.6004C7.04183 26.6004 1.40039 20.959 1.40039 14.0004C1.40039 7.04141 7.04183 1.40039 14.0004 1.40039C20.959 1.40039 26.6004 7.04141 26.6004 14.0004C26.6004 20.959 20.959 26.6004 14.0004 26.6004ZM10.7891 19.9379V11.3586H7.93769V19.9379H10.7891Z"
        fill="currentColor"
      />
    </svg>
  ),
  viewBox: '0 0 28 28',
};

export default linkedIn;
