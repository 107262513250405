import React from 'react';

const camera = {
  path: (
    <svg fill="none">
      <path
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M16.144 5.832h-2.387c-.177 0-.265-.183-.265-.274v-.092c0-.825-.619-1.466-1.415-1.466H7.835C7.04 4 6.42 4.641 6.42 5.466v.091c0 .184-.177.275-.265.275H3.768C2.796 5.832 2 6.657 2 7.664v6.504C2 15.176 2.796 16 3.768 16h12.464c.973 0 1.768-.824 1.768-1.832V7.664c-.088-1.007-.884-1.832-1.856-1.832z"
      />
      <path
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M10 14a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"
      />
      <circle cx="15.514" cy="8.278" r=".75" fill="currentColor" />
    </svg>
  ),
  viewBox: '0 0 20 20',
};

export default camera;
