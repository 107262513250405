import { theme } from '@chakra-ui/core';
import colors from './colors';
import fontSizes from './fontSizes';
import icons from './icons/index';
import radii from './radii';
import shadows from './shadows';
import space from './space';

export default {
  ...theme,
  fonts: {
    body: 'ProximaNova, sans-serif',
    heading: 'AdellePE, serif',
    mono: 'Menlo, monospace',
  },
  colors: {
    ...theme.colors,
    ...colors,
  },
  shadows: {
    ...theme.shadows,
    ...shadows,
  },
  fontSizes: {
    ...theme.fontSizes,
    ...fontSizes,
  },
  radii: {
    ...theme.radii,
    ...radii,
  },
  space,
  icons: {
    ...theme.icons,
    ...icons,
  },
};
