import React from 'react';

const ellipse = {
  path: (
    <svg fill="none">
      <path
        fill="currentColor"
        d="M15 10a1.5 1.5 0 103 0 1.5 1.5 0 00-3 0zM8.5 10a1.5 1.5 0 103 0 1.5 1.5 0 00-3 0zM2 10a1.5 1.5 0 103 0 1.5 1.5 0 00-3 0z"
      />
    </svg>
  ),
  viewBox: '0 0 20 20',
};

export default ellipse;
