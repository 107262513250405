import { ThemeProvider } from '@chakra-ui/core';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import client from '../graphql/client';
import theme from '../theme';
import { AuthProvider } from './auth';

export interface IAppProvidersProps {
  children: any;
}

export default function AppProviders({ children }: IAppProvidersProps) {
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <AuthProvider>{children}</AuthProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}
