export default {
  xs: '0.6875rem', // 11px
  sm: '0.75rem', // 12px
  sm2: '0.875rem', // 14px
  md: '1rem', // 16px
  lg: '1.25rem', // 20px
  xl: '1.625rem', // 26px
  '2xl': '2.25rem', // 36px
  '3xl': '3rem', //48px
  '4xl': '4rem', // 64px
  '5xl': '5.25rem', // 84px
  '6xl': '7rem', // 112px
  '7xl': '9.375rem', // 150px
};
