import React from 'react';
import * as Bowser from 'bowser';
import { Box, Link } from '@chakra-ui/core';
import { HEADER_HEIGHT } from '../Header';

const browser = Bowser.parse(window.navigator.userAgent);

export default function BrowserSupportBanner() {
  if (browser.browser.name === 'Internet Explorer') {
    return (
      <Box
        position="fixed"
        top={HEADER_HEIGHT}
        left={0}
        backgroundColor="#ED4242"
        color="white"
        textAlign="center"
        padding="1rem"
        width="100%"
        zIndex={10000}
      >
        This site isn't well supported with your browser. For the best
        experience, use{' '}
        <Link
          href="https://www.google.com/chrome/"
          target="_blank"
          textDecoration="underline"
        >
          Google Chrome
        </Link>{' '}
        or{' '}
        <Link
          href="https://www.mozilla.org/en-US/firefox/new/"
          target="_blank"
          textDecoration="underline"
        >
          Mozilla Firefox
        </Link>
      </Box>
    );
  } else {
    return <></>;
  }
}
