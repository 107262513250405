import { IToast, useToastOptions } from '@chakra-ui/core';
import { ApolloError } from 'apollo-boost';
import {
  differenceInDays,
  differenceInHours,
  format,
  parse,
  parseISO,
} from 'date-fns';
import UIDGenerator from 'uid-generator';
import { decodeDelimitedArray, encodeDelimitedArray } from 'use-query-params';
import {
  Address,
  Company,
  Expertise,
  ExpertiseType,
  Maybe,
  Order,
  OrderBy,
  UserInitiative,
} from '../generated/graphql';
import colors from '../theme/colors';

export const toastSuccess = (
  toast: (props: useToastOptions) => void,
  description: string,
  options: IToast = {
    title: 'Success',
    status: 'success',
    duration: 9000,
    isClosable: true,
  },
) => {
  toast({
    ...options,
    description,
  });
};

export const toastError = (
  toast: (props: useToastOptions) => void,
  error: Error | ApolloError,
  options: IToast = {
    title: 'An error occurred.',
    status: 'error',
    duration: 9000,
    isClosable: true,
  },
) => {
  // if (error instanceof ApolloError) {
  //   error.graphQLErrors.forEach(({ message }) => {
  //     toast({
  //       ...options,
  //       description: message,
  //     });
  //   });
  // }

  toast({
    ...options,
    description: error.message,
  });
};

/** Uses a comma to delimit entries. e.g. ['a', 'b'] => qp?=a,b */
export const CommaArrayParam = {
  encode: (array: (string | null)[] | null | undefined) =>
    encodeDelimitedArray(array, ','),
  decode: (arrayStr: string | (string | null)[] | null | undefined) =>
    decodeDelimitedArray(arrayStr, ','),
};

export const BooleanParam = {
  encode: (value: boolean | null | undefined) => {
    if (value === null || value === undefined) {
      return undefined;
    } else {
      return value ? 'true' : 'false';
    }
  },
  decode: (value: string | (string | null)[] | null | undefined) =>
    Boolean(value),
};

export const formatTitle = (company?: Maybe<Company>) => {
  if (!company) {
    return undefined;
  }

  const at = company?.title ? 'at ' : '';

  return `${company?.title} ${at}${company?.name}`;
};

export const formatAddress = (address?: Maybe<Address>) => {
  if (!address) {
    return undefined;
  }

  const { streetAddress, locality, region, postalCode } = address;

  const comma =
    (streetAddress || locality) && (region || postalCode) ? ',' : '';
  return `${streetAddress ?? ''} ${locality ?? ''}${comma} ${region ?? ''} ${
    postalCode ?? ''
  }`;
};

export const formatDateToString = (date?: Maybe<Date>, fmt = 'MM-dd-yyyy') => {
  if (!date) return null;

  return format(date, fmt);
};

export const formatDate = (date?: Maybe<string>, fmt = 'MM-dd-yyyy') => {
  try {
    return format(parseISO(date ?? ''), fmt);
  } catch (e) {
    return '';
  }
};

export const formatDateReceivedAge = (date?: Maybe<string>) => {
  try {
    const days = differenceInDays(new Date(), parseISO(date ?? ''));
    const word = days > 1 ? 'Days' : 'Day';
    return `${days} ${word} Old`;
  } catch (e) {
    return '';
  }
};

export const formatDateReceivedAgeInHours = (date?: Maybe<string>) => {
  try {
    const days = differenceInHours(new Date(), parseISO(date ?? ''));
    const word = days > 1 ? 'Hours' : 'Hour';
    return `${days} ${word} Ago`;
  } catch (e) {
    return '';
  }
};

export const parseDate = (date?: Maybe<string>) => {
  try {
    if (!date) return null;

    return parseISO(date);
  } catch (e) {
    return null;
  }
};

export const parseDateFormat = (date?: Maybe<string>, fmt = 'MM-dd-yyyy') => {
  try {
    if (!date) return null;

    return parse(date, fmt, new Date());
  } catch (e) {
    return null;
  }
};

export const formatYears = (years?: Maybe<number>, empty: any = 'N/A') => {
  if (!years) {
    return empty;
  }

  const word = years > 1 ? 'Years' : 'Year';

  return `${years} ${word}`;
};

export const formatText = (text?: Maybe<string>, empty: any = 'N/A') => {
  if (!text) {
    return empty;
  }

  return text;
};

export const formatFullName = (
  firstName?: Maybe<string>,
  lastName?: Maybe<string>,
) => {
  return (firstName ?? '') + ' ' + (lastName ?? '');
};

export const formatPhoneNumber = (phoneNumber?: Maybe<string>) => {
  const v = phoneNumber;
  if (phoneNumber!.length === 10) {
    return v ? `+1 (${v.slice(0, 3)}) ${v.slice(3, 6)}-${v.slice(6)}` : '';
  }
  return v ? `+${v[0]} (${v.slice(1, 4)}) ${v.slice(4, 7)}-${v.slice(7)}` : '';
};

export const unformatPhoneNumber = (phoneNumber?: Maybe<string>) => {
  const result = phoneNumber ? phoneNumber.replace(/\D/g, '') : '';
  return result === '1' ? '' : result;
};

export const randomGenerator = (size = 128) => {
  return new UIDGenerator(size).generateSync();
};

export const formatProfileStrength = (value: number) => {
  switch (true) {
    case value > 80:
      return { label: 'Strong', color: colors.green[500] };
    case value > 50:
      return { label: 'Moderate', color: colors.orange[400] };
    default:
      return { label: 'Weak', color: colors.red[500] };
  }
};

export const groupExpertisesByType = (expertises: Expertise[]) => {
  return {
    domains: expertises
      .filter(expertise => expertise.type === ExpertiseType.Domain)
      .map(expertise => expertise.name!),
    subjectMatters: expertises
      .filter(expertise => expertise.type === ExpertiseType.SubjectMatter)
      .map(expertise => expertise.name!),
    technologyProviders: expertises
      .filter(expertise => expertise.type === ExpertiseType.TechnologyProvider)
      .map(expertise => expertise.name!),
  };
};

export const groupInitiatives = (userInitiatives: UserInitiative[]) => {
  return {
    upcoming: userInitiatives
      .filter(userInitiative => !userInitiative.hasPast)
      .map(userInitiative => userInitiative.initiative!.name!),
    past: userInitiatives
      .filter(userInitiative => userInitiative.hasPast)
      .map(userInitiative => userInitiative.initiative!.name!),
  };
};

export const toggleOrderBy = (column: string, currentOrderBy: OrderBy) => {
  let order: Order | undefined = Order.Desc;

  // Toggle order direction
  if (currentOrderBy.column === column) {
    switch (currentOrderBy.order) {
      case Order.Desc:
        order = Order.Asc;
        break;
      case Order.Asc:
      default:
        order = Order.Desc;
    }
  }

  return {
    column,
    order,
  } as OrderBy;
};

export function groupCheckboxValues(values: {}) {
  const result: Record<string, any> = {};

  Object.entries(values).forEach(([key, value]) => {
    const [name] = key.split('-');
    if (!result.hasOwnProperty(name)) {
      result[name] = [];
    }

    if (value) result[name].push(value);
  });

  return result;
}

export const ExpertiseTypeMap = {
  [ExpertiseType.Domain]: 'Domain',
  [ExpertiseType.SubjectMatter]: 'Subject Matter',
  [ExpertiseType.TechnologyProvider]: 'Technology Provider',
};

export function hideUsersnap() {
  // @ts-ignore
  // if (window.Usersnap) {
  //   // @ts-ignore
  //   return window.Usersnap.hideButton();
  // } else {
  //   setTimeout(() => {
  //     hideUsersnap();
  //   }, 1000);
  // }
}
export function showUsersnap() {
  // @ts-ignore
  // if (window.Usersnap) {
  //   // @ts-ignore
  //   return window.Usersnap.showButton();
  // } else {
  //   setTimeout(() => {
  //     showUsersnap();
  //   }, 1000);
  // }
}
