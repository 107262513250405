import React from 'react';

const download = {
  path: (
    <svg fill="none">
      <path
        d="M11.7562 14.0352V14.7864C11.7562 15.8909 10.8607 16.7864 9.75617 16.7864H5.25616C4.15159 16.7864 3.25616 15.8909 3.25616 14.7864V5.28638C3.25616 4.18181 4.1516 3.28638 5.25616 3.28638H9.75617C10.8607 3.28638 11.7562 4.18181 11.7562 5.28638V6.03765"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M6.01757 9.99957L17.0787 9.99957"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M14.0033 6.60669L17.5592 9.99999L14.0033 13.3933"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  ),
  viewBox: '0 0 20 20',
};

export default download;
