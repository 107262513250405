import { ColorHues } from '@chakra-ui/core';

export default {
  orange: {
    50: '',
    100: '',
    200: '#FDEAE4',
    300: '#FD7D5D',
    400: '#F17151',
    500: '',
    600: '',
    700: '',
    800: '',
    900: '',
  } as ColorHues,
  burntOrange: {
    50: '',
    100: '',
    200: '',
    300: '#C43C0B',
    400: '#B33702',
    500: '',
    600: '',
    700: '',
    800: '',
    900: '',
  } as ColorHues,
  peach: '#FDEAE4',
  silver: '#F2F4FA',
  silverOpacity: 'rgba(240, 243, 249, 0.5)',
  slateBlue: '#363875',
  greyBlue: '#A0BCDB',
  lightBlue: '#F0F6FC',
  coldGrey: '#C0C5CC',
  green: {
    50: '',
    100: '',
    200: '',
    300: '',
    400: '',
    500: '#5DB550',
    600: '',
    700: '',
    800: '',
    900: '',
  } as ColorHues,

  red: {
    50: '',
    100: '',
    200: '',
    300: '',
    400: '',
    500: '#ED4242',
    600: '',
    700: '',
    800: '',
    900: '',
  } as ColorHues,

  grey: {
    50: '#CFCEED',
    100: '#FCFDFF',
    200: '#E5E5E5',
    300: '#DCDFE6',
    400: '#8B9399',
    500: '#495057',
    600: '',
    700: '',
    800: '',
    900: '',
  } as ColorHues,

  none: {
    50: '#00000000',
    100: '#00000000',
    200: '#00000000',
    300: '#00000000',
    400: '#00000000',
    500: '#00000000',
    600: '#00000000',
    700: '#00000000',
    800: '#00000000',
    900: '#00000000',
  } as ColorHues,

  dark: '#1D1E1F',

  primaryText: '#495057',
  secondaryText: '#8B9399',
  error: '#FC3535',
  success: '#5DD949',
};
