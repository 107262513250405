import React, { Suspense } from 'react';
import { QueryParamProvider } from 'use-query-params';
import { Flex } from '@chakra-ui/core';
import Loader from './components/Loader';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import BrowserSupportBanner from './components/BrowserSupportBanner';
import { useAuth0 } from '@auth0/auth0-react';

const UnauthenticatedRoutes = React.lazy(() =>
  import('./routes/unauthenticated'),
);
const AuthenticatedRoutes = React.lazy(() => import('./routes/authenticated'));

function App() {
  const { user } = useAuth0();

  return (
    <>
      <Helmet>
        <title>
          An exclusive national network of senior IT executives | Captive Eight
          Club
        </title>
      </Helmet>
      <Suspense fallback={<Loader />}>
        <Flex direction="column" h="100%">
          <BrowserSupportBanner />
          <Router>
            <QueryParamProvider ReactRouterRoute={Route}>
              {/* {userType === 'ADMIN'? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />} */}
              {user ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />}
            </QueryParamProvider>
          </Router>
        </Flex>
      </Suspense>
    </>
  );
}

export default App;
