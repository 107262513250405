import React from 'react';

const close = {
  path: (
    <path
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M2.01 17.99L17.99 2.01M17.99 17.99L2.01 2.01"
    />
  ),
  viewBox: '0 0 20 20',
};

export default close;
